import { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useAppDispatch } from "../../redux";

import SectionHeader from "../section-header";
import FormSelect from "../form-select";
import Button from "../button";
import FormInput from "../form-input";

import { setAlert } from "../../redux/components/components-slice";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  onSuccess: any;
};

const NewCustomerModal = ({ isOpen, close, onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const [segment, setSegment] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<any>(false);

  useEffect(() => {
    resetModal();
  }, [isOpen]);

  const resetModal = () => {
    setSegment(null);
    setName("");
    setEmail("");
    setPhone("");
    setState("");
    setIsSubmitting(false);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    console.log(name, email, phone, state, "name");

    setTimeout(() => {
      dispatch(setAlert(true, "success", "New customer created!"));
      onSuccess();
      close();
    }, 2000);
  };

  const segmentOptions = [
    { label: "Tech-savvy bargain hunters", value: "value-1" },
    { label: "Health-conscious millennials", value: "value-2" },
    { label: "Fashion-forward trendsetters", value: "value-3" },
    { label: "Eco-friendly consumers", value: "value-4" },
  ];

  return (
    <Modal className="new_campaign_modal size_md" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          title="New Customer"
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="form">
          <FormInput
            type="name"
            placeholder="Name"
            readOnly={isSubmitting}
            onChange={(e) => setName(e.target.value)}
          />

          <FormInput
            type="email"
            placeholder="Email"
            readOnly={isSubmitting}
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormInput
            type="number"
            placeholder="Phone"
            readOnly={isSubmitting}
            onChange={(e) => setPhone(e.target.value)}
          />

          <FormInput
            type="text"
            placeholder="State"
            readOnly={isSubmitting}
            onChange={(e) => setState(e.target.value)}
          />

          <FormSelect
            name="filterSelect"
            defaultValue={segment}
            placeholder="Select Segment"
            options={segmentOptions}
            onChange={(value: any) => setSegment(value)}
          />

          <div className="modal_action">
            <Button
              text="Create Customer"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewCustomerModal;
