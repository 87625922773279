import { useEffect, useState } from "react";

import intializeFiller from "../assets/svg/initialize_filler.svg";
import gatherFiller from "../assets/svg/gather_filler.svg";
import analyzeFiller from "../assets/svg/analyze_filler.svg";
import generateFiller from "../assets/svg/generate_filler.svg";
import finalizeFiller from "../assets/svg/finalize_filler.svg";
import almostFiller from "../assets/svg/almost_filler.svg";
import completeFiller from "../assets/svg/complete_filler.svg";

import Progress from "./progress";

interface Filler {
  title: string;
  color: string;
  icon: string;
  progress: number;
}

const fillerData: Filler[] = [
  {
    title: "Initializing...",
    color: "#94A3B8",
    icon: intializeFiller,
    progress: 10,
  },
  {
    title: "Gathering Information...",
    color: "#2563EB",
    icon: gatherFiller,
    progress: 20,
  },
  {
    title: "Analyzing your requests",
    color: "#38BDF8",
    icon: analyzeFiller,
    progress: 30,
  },
  {
    title: "Generating results...",
    color: "#F6A723",
    icon: generateFiller,
    progress: 60,
  },
  {
    title: "Finalizing output...",
    color: "#2563EB",
    icon: finalizeFiller,
    progress: 70,
  },
  {
    title: "Almost there....",
    color: "#ED4F9D",
    icon: almostFiller,
    progress: 80,
  },
  {
    title: "Complete",
    color: "#2563EB",
    icon: completeFiller,
    progress: 100,
  },
];

interface Props {
  loading: boolean;
  isDelegatedToAIFiller: boolean;
  setIsDelegatedToAIFiller: React.Dispatch<React.SetStateAction<boolean>>;
  nextAction: () => void;
}

const AIFillers = ({
  loading,
  isDelegatedToAIFiller,
  setIsDelegatedToAIFiller,
  nextAction,
}: Props) => {
  const [currentFillerIndex, setCurrentFillerIndex] = useState(0);

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => {
      if (currentFillerIndex >= fillerData.length - 1)
        return clearInterval(intervalId);
      setCurrentFillerIndex((prevIndex) => {
        return (prevIndex + 1) % fillerData.length;
      });
    }, 6000); // Adjust the interval time as needed

    return () => clearInterval(intervalId);
  }, [currentFillerIndex]);

  useEffect(() => {
    console.log("currentFillerIndex:", currentFillerIndex);
    console.log("fillerData.length - 1", fillerData.length - 1);
    if (isDelegatedToAIFiller && currentFillerIndex === fillerData.length - 1) {
      setTimeout(() => {
        setIsDelegatedToAIFiller(false);
        nextAction();
      }, 3000);
    }
  }, [
    isDelegatedToAIFiller,
    nextAction,
    setIsDelegatedToAIFiller,
    currentFillerIndex,
  ]);

  return (
    <div className="ai_fillers_container">
      {
        fillerData.map((filler: Filler, index: number) => (
          <div className="filler ">
            <div className="icon_filler">
              <img src={filler.icon} alt="" />
              <p>{filler.title}</p>
            </div>
            <Progress value={filler.progress} progressColor={filler.color} />
            <p>
              {fillerData.length - 1 === index
                ? "Setting up your campaigns..."
                : "Please wait..."}
            </p>
          </div>
        ))[currentFillerIndex]
      }
    </div>
  );
};

export default AIFillers;
