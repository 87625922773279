// import Icon from "../assets/svg";

type Props = {
  text: string;
  icon?: string;
};

const NoData = ({ text }: Props) => {
  return (
    <div className="no_data_container">
      {/* <Icon name={icon ?? "sadSquare"} /> */}
      <p>{text}</p>
    </div>
  );
};

export default NoData;
