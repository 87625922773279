import { IconType } from ".";

export const ArrowBack = ({ size, color }: IconType) => (
  <svg
    width={size ?? "24"}
    height={size ?? "24"}
    viewBox={size ? `0 0 ${size} ${size}` : "0 0 24 24"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13L5 9M5 9L9 5M5 9H16C17.0609 9 18.0783 9.42143 18.8284 10.1716C19.5786 10.9217 20 11.9391 20 13C20 14.0609 19.5786 15.0783 18.8284 15.8284C18.0783 16.5786 17.0609 17 16 17H15"
      stroke={color ?? "#64748B"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
