type ProgressProps = {
  value: number;
  progressColor?: string;
};

const Progress = ({ value, progressColor }: ProgressProps) => {
  return (
    <div className="progress_container">
      <div
        className="progress_inner"
        style={{ width: `${value}%`, background: progressColor }}
      ></div>
    </div>
  );
};

export default Progress;
