import { Analytics } from "./analytics";
import { ArrowBack } from "./arrow-back";
import { ArrowForward } from "./arrow-forward";
import { ArrowCircleLeft } from "./arrow-circle-left";
import { ArrowCircleRight } from "./arrow-circle-right";
import { Bell } from "./bell";
import { Calendar } from "./calendar";
import { CalendarBlack } from "./calendar-black";
import { ChevronDown } from "./chevron-down";
import { ChevronRight } from "./chevron-right";
import { Clock } from "./clock";
import { Close } from "./close";
import { Connect } from "./connect";
import { DotsH } from "./dots-h";
import { DotsV } from "./dots-v";
import { ErrorCircle } from "./error-circle";
import { Exclude } from "./exclude";
import { ExternalLink } from "./external-link";
import { EyeOff } from "./eye-off";
import { Eye } from "./eye";
import { Graph } from "./graph";
import { Grid } from "./grid";
import { InfoCircle } from "./info-circle";
import { Integrate } from "./integrate";
import { List } from "./list";
import { Lock } from "./lock";
import { Logout } from "./logout";
import { Mail } from "./mail";
import { Menu } from "./menu";
import { Pause } from "./pause";
import { Phone } from "./phone";
import { Plus } from "./plus";
import { Printer } from "./printer";
import { Refresh } from "./refresh";
import { Regenerate } from "./regenerate";
import { SadSquare } from "./sad-square";
import { Search } from "./search";
import { Settings } from "./settings";
import { StarFilled } from "./star-filled";
import { Star } from "./star";
import { Store } from "./store";
import { Trash } from "./trash";
import { Upload } from "./upload";
import { User } from "./user";

type IconProps = {
  name: string;
  size?: string;
  color?: string;
};

export type IconType = Omit<IconProps, "name">;

const Icon = ({ name, size, color }: IconProps) => {
  switch (name) {
    case "analytics":
      return <Analytics />;
    case "arrowBack":
      return <ArrowBack size={size} color={color} />;
    case "arrowForward":
      return <ArrowForward />;
    case "arrowCircleLeft":
      return <ArrowCircleLeft />;
    case "arrowCircleRight":
      return <ArrowCircleRight />;
    case "bell":
      return <Bell />;
    case "calendar":
      return <Calendar />;
    case "calendarBlack":
      return <CalendarBlack />;
    case "chevronDown":
      return <ChevronDown />;
    case "chevronRight":
      return <ChevronRight />;
    case "clock":
      return <Clock />;
    case "close":
      return <Close />;
    case "connect":
      return <Connect />;
    case "dotsH":
      return <DotsH />;
    case "dotsV":
      return <DotsV />;
    case "errorCircle":
      return <ErrorCircle />;
    case "exclude":
      return <Exclude />;
    case "externalLink":
      return <ExternalLink />;
    case "eyeOff":
      return <EyeOff />;
    case "eye":
      return <Eye />;
    case "graph":
      return <Graph />;
    case "grid":
      return <Grid />;
    case "infoCircle":
      return <InfoCircle />;
    case "integrate":
      return <Integrate />;
    case "list":
      return <List />;
    case "lock":
      return <Lock />;
    case "logout":
      return <Logout />;
    case "mail":
      return <Mail />;
    case "menu":
      return <Menu />;
    case "pause":
      return <Pause />;
    case "phone":
      return <Phone />;
    case "plus":
      return <Plus />;
    case "printer":
      return <Printer />;
    case "refresh":
      return <Refresh />;
    case "regenerate":
      return <Regenerate />;
    case "sadSquare":
      return <SadSquare size={size} color={color} />;
    case "search":
      return <Search />;
    case "settings":
      return <Settings />;
    case "starFilled":
      return <StarFilled />;
    case "star":
      return <Star />;
    case "store":
      return <Store />;
    case "trash":
      return <Trash />;
    case "upload":
      return <Upload />;
    case "user":
      return <User />;
    default:
      return <Close />;
  }
};

export default Icon;
