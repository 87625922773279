export const Graph = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8335 11.0002C1.8335 6.67895 1.8335 4.51835 3.17592 3.17592C4.51835 1.8335 6.67895 1.8335 11.0002 1.8335C15.3214 1.8335 17.482 1.8335 18.8244 3.17592C20.1668 4.51835 20.1668 6.67895 20.1668 11.0002C20.1668 15.3214 20.1668 17.482 18.8244 18.8244C17.482 20.1668 15.3214 20.1668 11.0002 20.1668C6.67895 20.1668 4.51835 20.1668 3.17592 18.8244C1.8335 17.482 1.8335 15.3214 1.8335 11.0002Z"
      stroke="#64748B"
      stroke-width="1.5"
    />
    <path
      d="M6.4165 12.8332L8.06365 10.8566C8.71636 10.0733 9.04272 9.68172 9.47206 9.68172C9.9014 9.68172 10.2278 10.0733 10.8805 10.8566L11.1192 11.1431C11.7719 11.9263 12.0983 12.318 12.5276 12.318C12.957 12.318 13.2833 11.9263 13.936 11.1431L15.5832 9.1665"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);
