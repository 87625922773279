import { createSlice } from "@reduxjs/toolkit";
import { API_URLS } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError } from "../../utils/functions";

type Props = {};
export const initialState: Props = {};

// Slice
const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
});
export default slice.reducer;

// Actions

export const getAISyncToVectorDBAction = () => async (dispatch: any) => {
  try {
    const response = await fetch(API_URLS?.aiSyncToVectorDB);
    const resp = await response.json();

    return resp;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};
