import { useState } from "react";
import classNames from "classnames";
import SunEditor from "suneditor-react";

import CampaignFooter from "./campaign-footer";
import CampaignReport from "./campaign-report";

import Icon from "../../assets/svg";

import TagSelect from "./tag-select";

import Button from "../../components/button";
import DropDown from "../../components/dropdown";
import FormInput from "../../components/form-input";
import Loader from "../../components/loader";
import SectionHeader from "../../components/section-header";
import CampaignMessagePreviewModal from "../../components/modal/campaign-message-preview-modal";

import { getBrandToneClassNames } from "../../utils/functions";
import { brandTags, writingApproach } from "../../utils/appdata";

type Props = {
  isFetchingCampaignData: boolean;
  selectedCampaign: any;
  selectedTags: Array<{ tag: string }> | [];
  setSelectedTags: React.Dispatch<React.SetStateAction<any>>;
  selectedWritingApproach: string;
  setSelectedWritingApproach: React.Dispatch<React.SetStateAction<string>>;
  analytics: any;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<any>>;
  setIsCampaignAiAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCampaignRecepientModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  rewriteMsg: (additionalContext: string) => Promise<void>;
  setIsCampaignScheduleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dateDisplay: (startDate: Date, endDate: Date) => string;
  contentOptions: { label: string; action: () => void }[];
  isRewritingMsg: boolean;
  isEditingMsgContent: boolean;
  msgSubject: string;
  setMsgSubject: (value: React.SetStateAction<string>) => void;
  msgContent: string;
  setMsgContent: React.Dispatch<React.SetStateAction<string>>;
  getSunEditorInstance: (sunEditor: any) => void;
  sunEditorOptions: { fontSize: number[]; buttonList: string[][] };
  setIsEditingMsgContent: (value: React.SetStateAction<boolean>) => void;
  setCampaignInitialSubjectAndContent: () => void;
  updateCampaign: () => Promise<void>;
  isStartingCampaign: boolean;
  startCampaign: () => void;
  setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCampaignReportExpanded: boolean;
  setIsCampaignReportExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  pauseCampaign: () => void;
  continueCampaign: () => void;
  isPausingCampaign: boolean;
  isContinuingCampaign: boolean;
  isEndingCampaign: boolean;
  endCampaign: () => void;
};

const CampaignDetails = ({
  isFetchingCampaignData,
  selectedCampaign,
  selectedTags,
  setSelectedTags,
  selectedWritingApproach,
  setSelectedWritingApproach,
  analytics,
  setSelectedCampaign,
  setIsCampaignAiAgentModalOpen,
  setIsCampaignRecepientModalOpen,
  rewriteMsg,
  setIsCampaignScheduleModalOpen,
  dateDisplay,
  contentOptions,
  isRewritingMsg,
  isEditingMsgContent,
  msgSubject,
  setMsgSubject,
  msgContent,
  setMsgContent,
  getSunEditorInstance,
  sunEditorOptions,
  setIsEditingMsgContent,
  setCampaignInitialSubjectAndContent,
  updateCampaign,
  isStartingCampaign,
  startCampaign,
  setIsConfirmationModalOpen,
  isCampaignReportExpanded,
  setIsCampaignReportExpanded,
  pauseCampaign,
  continueCampaign,
  isPausingCampaign,
  isContinuingCampaign,
  isEndingCampaign,
  endCampaign,
}: Props) => {
  const [
    isCampaignMessagePreviewModalOpen,
    setIsCampaignMessagePreviewModalOpen,
  ] = useState(false);

  let defaultTags;

  if (selectedCampaign) {
    defaultTags = JSON.parse(selectedCampaign?.ai_tones)?.map(
      (tag: string) => ({ tag })
    );
  }

  const tags = selectedTags?.length === 0 ? defaultTags : selectedTags;

  console.log("defaultTags", defaultTags);

  const [initialTags, setInitialTags] = useState([...brandTags]);
  const [showWritingApproachDropDown, setShowWritingApproachDropDown] =
    useState(false);

  const [additionalContext, setAdditionalContext] = useState("");

  const handleRemoveSelectedTag = (tagName: string) => {
    setSelectedTags((prev: { tag: string }[]) => {
      setInitialTags((prev) => [...prev, { tag: tagName }]);
      return [...prev.filter((data: { tag: string }) => data.tag !== tagName)];
    });
  };

  const handleSelectWritingApproach = (approachType: string) => {
    setSelectedWritingApproach(approachType);
    setShowWritingApproachDropDown(false);
  };

  const handleAdditionalContext = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalContext(e.target.value);
  };

  return (
    <div
      className={classNames("campaign_details", {
        is_visible: isFetchingCampaignData || selectedCampaign,
        not_visible: isCampaignReportExpanded,
      })}
    >
      {isFetchingCampaignData ? (
        <Loader />
      ) : (
        selectedCampaign && (
          <>
            {selectedCampaign.status !== "draft" &&
              selectedCampaign.status !== "scheduled" && (
                <CampaignReport
                  reportData={{
                    ...analytics,
                    ...selectedCampaign,
                  }}
                  // close={() => setSelectedCampaign(null)}
                  isCampaignReportExpanded={isCampaignReportExpanded}
                  setIsCampaignReportExpanded={setIsCampaignReportExpanded}
                />
              )}

            <SectionHeader
              goBack={true}
              goBackOnClick={() => setSelectedCampaign(null)}
              title="Campaign Message"
              printer
              rightSide={
                selectedCampaign.status === "scheduled" ? (
                  <Button
                    text={"Preview"}
                    className="preview"
                    onClick={() => setIsCampaignMessagePreviewModalOpen(true)}
                  />
                ) : (
                  <></>
                )
              }
            />

            <div className="header">
              <div className="side">
                <div className="product">
                  <p>
                    Title: <span>{selectedCampaign?.campaign_title}</span>
                  </p>
                  <div className="dot"></div>
                </div>

                <div className="product">
                  <p>
                    AI Agent:{" "}
                    <span
                      className="link"
                      onClick={() => setIsCampaignAiAgentModalOpen(true)}
                    >
                      View Agent's Analysis, Rationale and Recommendations for
                      this Campaign
                    </span>
                  </p>
                  <div className="dot"></div>
                </div>

                <div className="product">
                  <p>
                    To:{" "}
                    <span
                      className="link"
                      onClick={() => setIsCampaignRecepientModalOpen(true)}
                    >
                      {Number(
                        JSON.parse(selectedCampaign?.campaign_recipients)
                          ?.length
                      ).toLocaleString("en-US")}{" "}
                      recipients
                    </span>
                  </p>
                  <div className="dot"></div>
                </div>
              </div>

              <div className="side">
                {/* <div className="icon" onClick={() => {}}>
                <Icon name="arrowForward" />
              </div>
              <div className="icon" onClick={() => {}}>
                <Icon name="star" />
              </div> */}
              </div>
            </div>

            <div className="content">
              {selectedCampaign.status === "draft" && (
                <div className="top">
                  <div className="brand">
                    <p>
                      This Campaign message is generated based on default tags
                      selected. For a different message tone for this campaign,
                      remove or replace any of the tags below and click "Rewrite
                      Message". <strong>Select a max of 3</strong>
                    </p>
                    <div className="tone_select">
                      <div>
                        {/* {selectedTags?.length === 0 ? (
                          <>
                            <TagSelect
                              tags={initialTags}
                              setSelectedTags={setSelectedTags}
                              setInitialTags={setInitialTags}
                            />
                          </>
                        ) : ( */}
                        <div className="brand_tags no-border">
                          {tags?.map((data: { tag: string }, index: number) => (
                            <div
                              className={classNames(
                                "tag",
                                getBrandToneClassNames(index, data.tag)
                              )}
                              key={data.tag}
                            >
                              <p
                                className={classNames(
                                  getBrandToneClassNames(index, data.tag)
                                )}
                              >
                                {index + 1}.
                              </p>
                              <p
                                className={classNames(
                                  getBrandToneClassNames(index, data.tag)
                                )}
                              >
                                {data.tag}
                              </p>
                              <div
                                className="remove_tag"
                                onClick={() =>
                                  handleRemoveSelectedTag(data.tag)
                                }
                              >
                                <Icon name="close" />
                              </div>
                            </div>
                          ))}
                          <TagSelect
                            tags={initialTags}
                            setSelectedTags={setSelectedTags}
                            setInitialTags={setInitialTags}
                          />
                        </div>
                        {/* )} */}
                      </div>
                    </div>

                    <div className="writing_approach">
                      <p>To change writing approach, select below.</p>
                      <div
                        className="writing_approach_select"
                        onClick={() =>
                          setShowWritingApproachDropDown((prev) => !prev)
                        }
                      >
                        {selectedWritingApproach ? (
                          <div className="approach_tag">
                            <p>{selectedWritingApproach}</p>
                          </div>
                        ) : (
                          <p>Pick approach</p>
                        )}
                        <Icon name="chevronDown" />
                      </div>
                      {showWritingApproachDropDown && (
                        <div className="approach_select_dropdown">
                          {writingApproach.map((data) => (
                            <div
                              className="approach_tag"
                              key={data.type}
                              onClick={() =>
                                handleSelectWritingApproach(data.type)
                              }
                            >
                              <p>{data.type}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="additional_context">
                      <p>
                        For additional context to rewrite this campaign message,
                        use below box to give instructions.
                      </p>
                      <input
                        placeholder="Enter an instruction"
                        onChange={handleAdditionalContext}
                      />
                    </div>
                  </div>
                  <div className="left">
                    <Button
                      text={"Preview"}
                      onClick={() => setIsCampaignMessagePreviewModalOpen(true)}
                    />
                    <div className="regenerate">
                      <Button
                        text={
                          isRewritingMsg ? "Rewriting..." : "Rewrite message"
                        }
                        onClick={() => rewriteMsg(additionalContext)}
                        leftIcon={"regenerate"}
                        disabled={isRewritingMsg}
                      />
                    </div>
                  </div>

                  <div
                    className="date"
                    onClick={() => setIsCampaignScheduleModalOpen(true)}
                  >
                    <Icon name="calendar" />
                    {dateDisplay(
                      new Date(selectedCampaign?.start_date),
                      new Date(selectedCampaign?.end_date)
                    )}
                  </div>
                </div>
              )}

              <div className="body">
                {selectedCampaign.status === "draft" && (
                  <div className="actions">
                    <DropDown toggler={<Icon name="dotsH" />}>
                      {contentOptions?.map((item: any, i: any) => (
                        <div
                          key={i}
                          className="dropdown-item"
                          onClick={item?.action}
                        >
                          {item?.label}
                        </div>
                      ))}
                    </DropDown>
                  </div>
                )}

                {isRewritingMsg ? (
                  <Loader />
                ) : (
                  <div className="body_content">
                    {!isEditingMsgContent ? (
                      <p className="subject">{msgSubject}</p>
                    ) : (
                      <FormInput
                        type="text"
                        placeholder="Subject"
                        value={msgSubject}
                        readOnly={false}
                        onChange={(e) => setMsgSubject(e.target.value)}
                      />
                    )}

                    {!isEditingMsgContent ? (
                      <div className="message">
                        <div
                          className={classNames("message_text", {
                            draft: selectedCampaign.status === "draft",
                          })}
                          onClick={() => setIsEditingMsgContent(true)}
                          dangerouslySetInnerHTML={{
                            __html: msgContent,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <>
                        <SunEditor
                          getSunEditorInstance={getSunEditorInstance}
                          name="message"
                          placeholder="Type your message here..."
                          setOptions={sunEditorOptions}
                          defaultValue={msgContent}
                          onChange={(content) => setMsgContent(content)}
                        />

                        <div className="actions">
                          <Button
                            text="Cancel"
                            className="btn_secondary"
                            onClick={() => {
                              setIsEditingMsgContent(false);
                              setCampaignInitialSubjectAndContent();
                            }}
                          />
                          <Button text="Save" onClick={updateCampaign} />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!isEditingMsgContent &&
              selectedCampaign.status !== "completed" && (
                <CampaignFooter
                  campaignData={selectedCampaign}
                  contentOptions={contentOptions}
                  isStartingCampaign={isStartingCampaign}
                  startCampaign={startCampaign}
                  setIsCampaignScheduleModalOpen={
                    setIsCampaignScheduleModalOpen
                  }
                  setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                  pauseCampaign={pauseCampaign}
                  continueCampaign={continueCampaign}
                  isPausingCampaign={isPausingCampaign}
                  isContinuingCampaign={isContinuingCampaign}
                  isEndingCampaign={isEndingCampaign}
                  endCampaign={endCampaign}
                />
              )}
          </>
        )
      )}
      {selectedCampaign ? (
        <CampaignMessagePreviewModal
          isOpen={isCampaignMessagePreviewModalOpen}
          close={() => setIsCampaignMessagePreviewModalOpen(false)}
          campaign={selectedCampaign}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CampaignDetails;
