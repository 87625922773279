export const Upload = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.88845 12.8704C3.35891 12.8704 3.74029 13.2517 3.74029 13.7222V17.1296C3.74029 17.3555 3.83003 17.5722 3.98978 17.732C4.14953 17.8917 4.3662 17.9815 4.59212 17.9815H16.5178C16.7437 17.9815 16.9604 17.8917 17.1201 17.732C17.2799 17.5722 17.3696 17.3555 17.3696 17.1296V13.7222C17.3696 13.2517 17.751 12.8704 18.2215 12.8704C18.6919 12.8704 19.0733 13.2517 19.0733 13.7222V17.1296C19.0733 17.8074 18.804 18.4574 18.3248 18.9367C17.8456 19.4159 17.1955 19.6852 16.5178 19.6852H4.59212C3.91436 19.6852 3.26436 19.4159 2.78511 18.9367C2.30586 18.4574 2.03662 17.8074 2.03662 17.1296V13.7222C2.03662 13.2517 2.418 12.8704 2.88845 12.8704Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.95263 2.8977C10.2853 2.56503 10.8246 2.56503 11.1573 2.8977L15.4165 7.15695C15.7491 7.48962 15.7491 8.02899 15.4165 8.36166C15.0838 8.69432 14.5445 8.69432 14.2118 8.36166L10.555 4.70475L6.89814 8.36166C6.56548 8.69432 6.02613 8.69432 5.69347 8.36166C5.3608 8.02899 5.3608 7.48962 5.69347 7.15695L9.95263 2.8977Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.555 2.64819C11.0254 2.64819 11.4068 3.02958 11.4068 3.50005V13.7223C11.4068 14.1927 11.0254 14.5741 10.555 14.5741C10.0845 14.5741 9.70312 14.1927 9.70312 13.7223V3.50005C9.70312 3.02958 10.0845 2.64819 10.555 2.64819Z"
      fill="white"
    />
  </svg>
);
