import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";

import SectionHeader from "../../components/section-header";
import Loader from "../../components/loader";
import ConfirmationModal from "../../components/modal/confirmation-modal";

import InsightsDetails from "./insight-details";

import {
  getInsightsByIdAction,
  deleteInsightsAction,
} from "../../redux/dashboard/insights-slice";

import Icon from "../../assets/svg";

type InsightInfoProps = {
  id: string;
  insightActionOptions: (
    status: string
  ) => { label: string; action: (id: string) => void }[] | undefined;
  setShowInsightDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const InsightInfoPage = ({
  id,
  insightActionOptions,
  setShowInsightDetails,
}: InsightInfoProps) => {
  const dispatch = useAppDispatch();

  const { isFetchingInsightData, insightData, isDeletingInsight } = useSelector(
    (state: any) => state.insightsSlice
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getInsightsByIdAction(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteInsight = () => {
    dispatch(deleteInsightsAction(id)).then((res: any) => {
      if (res?.success === true) {
        setShowInsightDetails(false);
        window.location.reload();
      }
    });
  };

  return (
    <React.Fragment>
      <div className=" insights_info_page_container">
        {isFetchingInsightData && <Loader />}

        {!isFetchingInsightData && (
          <>
            <SectionHeader
              goBack
              goBackOnClick={() => setShowInsightDetails(false)}
              title={insightData.insight_title}
              rightSide={
                <>
                  <div
                    className="icon"
                    onClick={() => setIsConfirmationModalOpen(true)}
                  >
                    <Icon name="trash" />
                  </div>
                </>
              }
            />

            <div className="content">
              <div className="right_side">
                <InsightsDetails
                  insightId={id}
                  insightData={insightData}
                  insightActionOptions={insightActionOptions}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        confirmationText={`Are you sure you want to delete ${insightData?.insight_title}?`}
        onSubmit={deleteInsight}
        loading={isDeletingInsight}
      />
    </React.Fragment>
  );
};

export default InsightInfoPage;
