import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError } from "../../utils/functions";

type Props = {
  isFetchingCampaigns: boolean;
  campaigns: [];
  isFetchingCampaignData: boolean;
  isCreatingCampaign: boolean;
  isSavingCampaign: boolean;
  isSavingCampaignSuccessful: boolean;
  isDeletingCampaign: boolean;
  isEndingCampaign: boolean;
  isContinuingCampaign: boolean;
  isPausingCampaign: boolean;
  isSchedulingCampaign: boolean;
  isStartingCampaign: boolean;
  isFetchingCampaignAnalytics: boolean;
};
export const initialState: Props = {
  isFetchingCampaigns: false,
  campaigns: [],
  isFetchingCampaignData: false,
  isCreatingCampaign: false,
  isSavingCampaign: false,
  isSavingCampaignSuccessful: true,
  isDeletingCampaign: false,
  isEndingCampaign: false,
  isContinuingCampaign: false,
  isPausingCampaign: false,
  isSchedulingCampaign: false,
  isStartingCampaign: false,
  isFetchingCampaignAnalytics: false,
};

// Slice
const slice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setIsFetchingCampaigns: (state, { payload }) => {
      state.isFetchingCampaigns = payload;
    },
    getCampaignsSuccess: (state, { payload }) => {
      state.campaigns = payload;
    },
    setIsFetchingCampaignData: (state, { payload }) => {
      state.isFetchingCampaignData = payload;
    },
    setIsCreatingCampaign: (state, { payload }) => {
      state.isCreatingCampaign = payload;
    },
    setIsSavingCampaign: (state, { payload }) => {
      state.isSavingCampaign = payload;
    },
    setIsSavingCampaignSuccessful: (state, { payload }) => {
      state.isSavingCampaignSuccessful = payload;
    },
    setIsDeletingCampaign: (state, { payload }) => {
      state.isDeletingCampaign = payload;
    },
    setIsEndingCampaign: (state, { payload }) => {
      state.isEndingCampaign = payload;
    },
    setIsContinuingCampaign: (state, { payload }) => {
      state.isContinuingCampaign = payload;
    },
    setIsPausingCampaign: (state, { payload }) => {
      state.isPausingCampaign = payload;
    },
    setIsSchedulingCampaign: (state, { payload }) => {
      state.isSchedulingCampaign = payload;
    },
    setIsStartingCampaign: (state, { payload }) => {
      state.isStartingCampaign = payload;
    },
    setIsFetchingCampaignAnalytics: (state, { payload }) => {
      state.isFetchingCampaignAnalytics = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingCampaigns,
  getCampaignsSuccess,
  setIsFetchingCampaignData,
  setIsCreatingCampaign,
  setIsSavingCampaign,
  setIsSavingCampaignSuccessful,
  setIsDeletingCampaign,
  setIsEndingCampaign,
  setIsContinuingCampaign,
  setIsPausingCampaign,
  setIsSchedulingCampaign,
  setIsStartingCampaign,
  setIsFetchingCampaignAnalytics,
} = slice.actions;

export const getCampaignsAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingCampaigns(true));

    const res = await getRequest({
      url: API_URLS?.getCampaigns,
      params: null,
    });

    dispatch(getCampaignsSuccess(res?.data?.data));
    dispatch(setIsFetchingCampaigns(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingCampaigns(false));
  }
};

export const getCampaignsByIdAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingCampaignData(true));

    const res = await getRequest({
      url: `${API_URLS?.getCampaignById}/${id}`,
      params: null,
    });

    dispatch(setIsFetchingCampaignData(false));

    return { success: true, data: res?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingCampaignData(false));
  }
};
export const getPersonalizedCampaignsByIdAction =
  (id: string) => async (dispatch: any) => {
    try {
      const res = await getRequest({
        url: `${API_URLS?.getPersonalizedCampaignById}/${id}`,
        params: null,
      });

      return { success: true, data: res?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const generateAICampaignAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingCampaign(true));
    const response = await fetch(API_URLS?.generateAICampaign);
    const resp = await response.json();

    dispatch(setIsCreatingCampaign(false));
    return { success: true, data: resp?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingCampaign(false));
  }
};

export const generateAICustomCampaignAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsCreatingCampaign(true));
      const response = await fetch(
        data?.segment_name
          ? API_URLS?.generateAISegmentCampaign
          : API_URLS?.generateAICustomCampaign,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const resp = await response.json();

      dispatch(setIsCreatingCampaign(false));
      return { success: true, data: resp?.data };
    } catch (err) {
      console.log("error__:", err);
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsCreatingCampaign(false));
    }
  };

export const rewriteUnsavedCampaignBodyAction =
  (data: { campaign_body: string }) => async (dispatch: any) => {
    try {
      const response = await fetch(API_URLS?.rewriteUnsavedCampaignBody, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const resp = await response.json();

      return { success: true, data: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const rewriteCampaignMsgAction =
  (
    id: string,
    brandTones: string,
    additionalContext: string,
    writingApproach: string
  ) =>
  async (dispatch: any) => {
    try {
      const response = await fetch(
        `${API_URLS?.rewriteCampaignMsg}/${id}/${brandTones}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ additionalContext, writingApproach }),
        }
      );
      const resp = await response.json();

      return { success: true, data: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postUpdateCampaignAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.updateCampaign,
        params: null,
        data,
        formData: false,
      });

      dispatch(setAlert(true, "success", res?.data?.message));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postAddCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsSavingCampaign(true));

    const res = await postRequest({
      url: data?.segment_id
        ? API_URLS?.addSegmentCampaign
        : API_URLS?.addCustomCampaign,
      params: null,
      data: data.payload,
      formData: false,
    });

    dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsSavingCampaign(false));

    return { success: true, campaign: res?.data?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsSavingCampaignSuccessful(false));
    dispatch(setIsSavingCampaign(false));
  }
};

export const deleteCampaignAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsDeletingCampaign(true));

    const res = await getRequest({
      url: `${API_URLS?.deleteCampaign}/${id}`,
      params: null,
    });

    dispatch(setIsDeletingCampaign(false));
    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsDeletingCampaign(false));
  }
};

export const endCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsEndingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.endCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsEndingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign ended successfully!"));
      return { success: true };
    } else {
      dispatch(setIsEndingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsEndingCampaign(false));
  }
};

export const continueCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsContinuingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.continueCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsContinuingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign continued successfully!"));
      return { success: true };
    } else {
      dispatch(setIsContinuingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsContinuingCampaign(false));
  }
};

export const pauseCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsPausingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.pauseCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsPausingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign paused successfully!"));
      return { success: true };
    } else {
      dispatch(setIsPausingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsPausingCampaign(false));
  }
};

export const scheduleCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsSchedulingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.scheduleCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsSchedulingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign scheduled successfully!"));
      return { success: true };
    } else {
      dispatch(setIsSchedulingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsSchedulingCampaign(false));
  }
};

export const sendCampaignEmailAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsStartingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.sendCampaignEmail,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsStartingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign sent successfully!"));
      return { success: true };
    } else {
      dispatch(setIsStartingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsStartingCampaign(false));
  }
};

export const getCampaignEmailAnalyticsAction =
  (id: string) => async (dispatch: any) => {
    dispatch(setIsFetchingCampaignAnalytics(true));

    try {
      const res = await getRequest({
        url: `${API_URLS?.campaignEmailAnalytics}/${id}`,
        params: null,
      });

      dispatch(setIsFetchingCampaignAnalytics(false));

      return {
        success: true,
        data: res?.data?.mailersend_response?.body?.data?.stats || [],
      };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingCampaignAnalytics(false));
    }
  };
