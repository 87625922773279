import { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

type Props = {
  toggler: React.ReactNode;
  children: React.ReactNode;
};

const DropDown = ({ toggler, children }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="dropdown_container"
    >
      <DropdownToggle className="drop">{toggler}</DropdownToggle>
      <DropdownMenu className="dropdown-menu" onClick={toggle}>
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropDown;
