import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import PageTitle from "../../components/page-title";
import Carousel from "../../components/carousel";
import FormInput from "../../components/form-input";
import Button from "../../components/button";

import { forgotPasswordAction } from "../../redux/auth/user-slice";

import { emailFormValidation } from "../../utils/functions";

type FormInputs = {
  email: string;
};

const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useSelector((state: any) => state.userSlice);
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);

    await dispatch(forgotPasswordAction(data)).then(() => {
      setStep(2);
    });

    setIsSubmitting(false);
  };

  const resendEmailLink = async () => {
    setIsSubmitting(true);

    await dispatch(forgotPasswordAction({})).then(() => {
      setStep(2);
    });

    setIsSubmitting(false);
  };

  if (user?.id && !isSubmitting) return <>{window.location.assign("/home")}</>;

  return (
    <React.Fragment>
      <PageTitle title="Reset Password" />

      <div className="sign_in_page">
        <div className="left_side">
          <Carousel />
        </div>

        <div className="form_container">
          {step === 1 ? (
            <form className="form">
              <p className="title">Forgot your password</p>
              <p className="sub_title">
                Enter the email address associated with your account and we will
                send you a link to reset your password.
              </p>

              <FormInput
                type="email"
                placeholder="Email"
                readOnly={isSubmitting}
                errorMessage={errors?.email?.message}
                inputRef={{
                  ...register("email", emailFormValidation(true)),
                }}
                leftIcon="mail"
              />

              <div className="actions">
                <Button
                  text="Continue"
                  type="submit"
                  onClick={handleSubmit((data) => submitForm(data))}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                />

                <p className="sign_up_text">
                  <Link to="/">Back to Sign In</Link>
                </p>
              </div>
            </form>
          ) : (
            <div className="form">
              <p className="title">Reset password link sent</p>
              <p className="sub_title">
                Thank you, check your email for instructions to reset your
                password
              </p>

              <div className="actions">
                <Button text="Sign In" onClick={() => history.push("/")} />

                <p className="sign_up_text">
                  Didn’t Receive an email?{" "}
                  <span className="link" onClick={resendEmailLink}>
                    Resend
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
