import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError } from "../../utils/functions";

type Props = {
  isFetchingCustomers: boolean;
  customers: { count: number; data: [] };
  isFetchingCustomerData: boolean;
  customerData: {};
  isFetchingCustomerInsight: boolean;
  customerInsight: {};
};
export const initialState: Props = {
  isFetchingCustomers: false,
  customers: { count: 0, data: [] },
  isFetchingCustomerData: true,
  customerData: {},
  isFetchingCustomerInsight: true,
  customerInsight: {},
};

// Slice
const slice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setIsFetchingCustomers: (state, { payload }) => {
      state.isFetchingCustomers = payload;
    },
    getCustomersSuccess: (state, { payload }) => {
      state.customers = payload;
    },
    setIsFetchingCustomerData: (state, { payload }) => {
      state.isFetchingCustomerData = payload;
    },
    getCustomerByIdSuccess: (state, { payload }) => {
      state.customerData = payload;
    },
    setIsFetchingCustomerInsight: (state, { payload }) => {
      state.isFetchingCustomerInsight = payload;
    },
    getCustomerInsightSuccess: (state, { payload }) => {
      state.customerInsight = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingCustomers,
  getCustomersSuccess,
  setIsFetchingCustomerData,
  getCustomerByIdSuccess,
  setIsFetchingCustomerInsight,
  getCustomerInsightSuccess,
} = slice.actions;

export const getCustomersAction =
  (params: { page_count: number; page_number: number } | null) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsFetchingCustomers(true));

      const res = await getRequest({
        url: API_URLS?.getCustomers,
        params,
      });

      dispatch(
        getCustomersSuccess({
          count: res?.data?.data?.length,
          totalCount: res?.data?.total_count,
          totalPages: 3,
          data: res?.data?.data?.map((item: any) => ({
            ...item,
            customer_data: JSON.parse(item?.customer_data),
          })),
        })
      );
      dispatch(setIsFetchingCustomers(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingCustomers(false));
    }
  };

export const getCustomersByIdAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingCustomerData(true));

    const res = await getRequest({
      url: `${API_URLS?.getCustomerById}/${id}`,
      params: null,
    });

    dispatch(
      getCustomerByIdSuccess({
        ...res?.data,
        customer_data: JSON.parse(res?.data?.customer_data),
        customer_orders: JSON.parse(res?.data?.customer_orders),
      })
    );
    dispatch(setIsFetchingCustomerData(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingCustomerData(false));
  }
};

export const getCustomerInsightAction =
  (id: string) => async (dispatch: any) => {
    try {
      dispatch(setIsFetchingCustomerInsight(true));
      const response = await fetch(`${API_URLS?.getCustomerInsight}/${id}`);
      const res = await response.json();

      dispatch(getCustomerInsightSuccess(res?.data));
      dispatch(setIsFetchingCustomerInsight(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingCustomerInsight(false));
    }
  };
