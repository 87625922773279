import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../redux";

import Navigations from "../dashboard/navigations";

import Logo from "./logo";

import Icon from "../assets/svg";

import { toggleSidebar } from "../redux/components/components-slice";
import { logoutUserAction } from "../redux/auth/user-slice";
import classNames from "classnames";

const Sidebar = () => {
  const dispatch = useAppDispatch();

  const { isSidebarOpen } = useSelector((state: any) => state.componentsSlice);

  const resScreenWidth = 851;
  const Navs: any = Navigations?.filter((x) => x?.unlist !== true);

  const topLinks = Navs?.slice(0, 5) || [];
  const bottomLinks = Navs?.slice(5, 6) || [];

  const logoutEl = Navigations?.slice(
    Navigations?.length - 1,
    Navigations?.length
  )?.[0];

  const handleSidebarClose = () => {
    if (window.innerWidth < resScreenWidth) {
      dispatch(toggleSidebar(false));
    }
  };
  useEffect(() => {
    handleSidebarClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`sidebar_container${
        isSidebarOpen ? " sidebar_container_open" : " sidebar_container_closed"
      }`}
    >
      <div className="sidebar_container_inner">
        <div className="logo_and_toggler">
          <div
            className="sidebar_toggler"
            onClick={() => dispatch(toggleSidebar(!isSidebarOpen))}
          >
            <Icon name="menu" />
          </div>

          {isSidebarOpen && <Logo />}
        </div>

        <hr />

        <div className="links_container">
          {topLinks?.map((nav: any, i: any) => (
            <React.Fragment key={i}>
              {nav?.to && (
                <NavLink
                  className="link"
                  to={nav?.to}
                  onClick={handleSidebarClose}
                >
                  <div className="bar"></div>

                  <div
                    className={classNames("icon_and_label", {
                      [nav?.icon]: nav?.icon,
                    })}
                  >
                    <Icon name={nav?.icon} />
                    {isSidebarOpen && <>{nav?.label}</>}
                  </div>

                  {/* {nav?.showCount && isSidebarOpen && (
                    <div className="count">150</div>
                  )} */}
                </NavLink>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="bottom_links">
          {bottomLinks?.length > 0 && (
            <div className="links_container">
              {bottomLinks?.map((nav: any, i: any) => (
                <React.Fragment key={i}>
                  <NavLink
                    className="link"
                    to={nav?.to}
                    onClick={handleSidebarClose}
                  >
                    <div className="bar"></div>

                    <div className="icon_and_label">
                      <Icon name={nav?.icon} />
                      {isSidebarOpen && <>{nav?.label}</>}
                    </div>
                  </NavLink>
                </React.Fragment>
              ))}
            </div>
          )}

          <div className="links_container">
            <div
              className="link logout"
              onClick={() => {
                handleSidebarClose();
                dispatch(logoutUserAction());
              }}
            >
              <div className="bar"></div>

              <div className="icon_and_label">
                <Icon name={logoutEl?.icon} />
                {isSidebarOpen && <>{logoutEl?.label}</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
