export const Grid = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.24984 3.66663H4.58317C4.07691 3.66663 3.6665 4.07703 3.6665 4.58329V8.24996C3.6665 8.75622 4.07691 9.16663 4.58317 9.16663H8.24984C8.7561 9.16663 9.1665 8.75622 9.1665 8.24996V4.58329C9.1665 4.07703 8.7561 3.66663 8.24984 3.66663Z"
      stroke="#64748B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4165 3.66663H13.7498C13.2436 3.66663 12.8332 4.07703 12.8332 4.58329V8.24996C12.8332 8.75622 13.2436 9.16663 13.7498 9.16663H17.4165C17.9228 9.16663 18.3332 8.75622 18.3332 8.24996V4.58329C18.3332 4.07703 17.9228 3.66663 17.4165 3.66663Z"
      stroke="#64748B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.24984 12.8333H4.58317C4.07691 12.8333 3.6665 13.2437 3.6665 13.75V17.4166C3.6665 17.9229 4.07691 18.3333 4.58317 18.3333H8.24984C8.7561 18.3333 9.1665 17.9229 9.1665 17.4166V13.75C9.1665 13.2437 8.7561 12.8333 8.24984 12.8333Z"
      stroke="#64748B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4165 12.8333H13.7498C13.2436 12.8333 12.8332 13.2437 12.8332 13.75V17.4166C12.8332 17.9229 13.2436 18.3333 13.7498 18.3333H17.4165C17.9228 18.3333 18.3332 17.9229 18.3332 17.4166V13.75C18.3332 13.2437 17.9228 12.8333 17.4165 12.8333Z"
      stroke="#64748B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
