import React, { useState, useEffect } from "react";

import PageTitle from "../../components/page-title";
import Topbar from "../../components/topbar";
import NavTabs from "../../components/navtabs";

import Profile from "./profile";
import Subscription from "./subscription";
import ChangePassword from "./change-password";
import AITraining from "./ai-training";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const tab = params.get("tab");
    if (tab) setActiveTab(tab);
  }, []);

  const switchTab = (id: string) => {
    setActiveTab(id);
  };

  let navigations = [
    {
      label: "Profile",
      id: "profile",
      content: <Profile />,
    },

    {
      label: "Change Password",
      id: "password",
      content: <ChangePassword />,
    },
    {
      label: "AI Training",
      id: "ai-training",
      content: <AITraining />,
    },
    {
      label: "",
      id: "subscription",
      content: <Subscription />,
    },
  ];

  return (
    <React.Fragment>
      <PageTitle title="Settings" />
      <Topbar title="Settings" onSearch={() => {}} hideLayoutStyle />

      <div className="page_container settings_page_container">
        <NavTabs
          activeTab={activeTab}
          toggleTab={(id: string) => switchTab(id)}
          navItems={navigations}
          tabPanes={navigations}
        />
      </div>
    </React.Fragment>
  );
};

export default SettingsPage;
