import LoaderBlue from "../assets/svg/loader-blue.svg";
import LoaderWhite from "../assets/svg/loader-white.svg";

const Loader = ({ white, text }: { white?: boolean; text?: string }) => {
  return (
    <div className="loader_img_container">
      <img
        src={white ? LoaderWhite : LoaderBlue}
        className="loader_img"
        alt="Loader img"
      />
      {text && <p>{text}</p>}
    </div>
  );
};

export default Loader;
