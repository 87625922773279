import Topbar from "../../components/topbar";
import NotificationDateFilter from "./notification-date-filter";

type Props = {
  generateWithAI?: () => void;
  generateCustom?: () => void;
  onFilter?: (value: string) => any;
  viewTypeOptions: Array<{ label: string; value: string }>;
  viewType: { label: string; value: string };
  setViewType: React.Dispatch<
    React.SetStateAction<{ label: string; value: string }>
  >;
};

const NotificationsTopbar = ({
  onFilter,
  viewType,
  setViewType,
  viewTypeOptions,
}: Props) => {
  const handleFilter = (selected: { label: string; value: string }) => {
    onFilter && onFilter(selected.value);
  };

  const topbarFilters = [
    {
      type: "select",
      value: viewType,
      options: viewTypeOptions,
      onChange: (value: any) => setViewType(value),
    },
    {
      type: "tag",
      label: "All",
      value: "40",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "tag",
      label: "Unread",
      value: "14",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "tag",
      label: "Read",
      value: "36",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "searchFilter",
      label: "Search anything...",
      children: <NotificationDateFilter />,
      onChange: (value: any) => setViewType(value),
    },
    {
      type: "dropSelect",
      variant: "checkbox",
      options: [
        { label: "All", value: "all" },
        { label: "Read", value: "read" },
        { label: "Unread", value: "unread" },
      ],
      label: "Select All",
      onChange: (value: any) => setViewType(value),
    },
    {
      type: "dropSelect",
      options: [
        { label: "Read", value: "read" },
        { label: "Unread", value: "unread" },
      ],
      label: "Mark as",
      onChange: (value: any) => setViewType(value),
    },
  ];

  return (
    <Topbar
      title="Notifications"
      onSearch={() => {}}
      filters={topbarFilters}
      hideAction
    />
  );
};

export default NotificationsTopbar;
