import LogoDefault from "../assets/img/logo.png";
import LogoLight from "../assets/img/logo-white.png";

type Props = {
  lightLogo?: boolean;
};

const Logo = ({ lightLogo }: Props) => {
  const handleClick = () => {
    window.location.assign("/home");
  };

  return (
    <div className="logo_container" onClick={handleClick}>
      <img
        className="icon"
        src={lightLogo ? LogoLight : LogoDefault}
        alt="KonvasAi logo"
      />
    </div>
  );
};

export default Logo;
