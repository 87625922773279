import { bellIcon, bullet, graph, letter } from "../../assets/img";
import Icon from "../../assets/svg";
import Button from "../../components/button";

const NotificationDropDown = () => {
  const handleViewAllNotifications = () => {
    window.location.assign("/notifications");
  };

  return (
    <div className="notification_dropdown">
      <div className="topbar">
        <div className="left">
          <p>NOTIFICATIONS</p>
          <img src={bellIcon} alt="notification_icon" />
        </div>
        <div className="right">
          <Icon name="close" />
        </div>
      </div>
      <div className="content">
        <div className="item">
          <div className="icon">
            <img src={letter} alt="" />
          </div>
          <div className="item_content">
            <p className="item_title">Your black friday campaign is thriving</p>
            <p className="item_detail">
              This campaign has reached 30 customers so far.{" "}
              <span>See more</span>
            </p>
            <p className="item_detail">27th May 2024, 12:32pm</p>
          </div>
          <div className="bullet">
            <img src={bullet} alt="" />
          </div>
        </div>

        <div className="item">
          <div className="icon">
            <img src={graph} alt="" />
          </div>
          <div className="item_content">
            <p className="item_title">You have not reviewed your analytics</p>
            <p className="item_detail">
              You have some new data on your analytics page.{" "}
              <span>See more</span>
            </p>
            <p className="item_detail">27th May 2024, 12:32pm</p>
          </div>
          <div className="bullet">
            <img src={bullet} alt="" />
          </div>
        </div>
        <div className="button_div">
          <Button
            text={"See all notifications"}
            onClick={handleViewAllNotifications}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationDropDown;
