export const Pause = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1014_22172)">
      <circle cx="8" cy="8.74023" r="6" stroke="#F6A723" stroke-width="1.5" />
      <path
        d="M2.33331 3.07487L5 1.4082"
        stroke="#F6A723"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6667 3.07487L11 1.4082"
        stroke="#F6A723"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33331 7.07422C5.33331 6.76359 5.33331 6.60828 5.38406 6.48576C5.45172 6.32241 5.58151 6.19263 5.74486 6.12497C5.86737 6.07422 6.02269 6.07422 6.33331 6.07422C6.64394 6.07422 6.79925 6.07422 6.92177 6.12497C7.08512 6.19263 7.2149 6.32241 7.28257 6.48576C7.33331 6.60828 7.33331 6.76359 7.33331 7.07422V10.4076C7.33331 10.7182 7.33331 10.8735 7.28257 10.996C7.2149 11.1594 7.08512 11.2891 6.92177 11.3568C6.79925 11.4076 6.64394 11.4076 6.33331 11.4076C6.02269 11.4076 5.86737 11.4076 5.74486 11.3568C5.58151 11.2891 5.45172 11.1594 5.38406 10.996C5.33331 10.8735 5.33331 10.7182 5.33331 10.4076V7.07422Z"
        stroke="#F6A723"
        stroke-width="1.5"
      />
      <path
        d="M8.66669 7.07422C8.66669 6.76359 8.66669 6.60828 8.71743 6.48576C8.7851 6.32241 8.91488 6.19263 9.07823 6.12497C9.20075 6.07422 9.35606 6.07422 9.66669 6.07422C9.97731 6.07422 10.1326 6.07422 10.2551 6.12497C10.4185 6.19263 10.5483 6.32241 10.6159 6.48576C10.6667 6.60828 10.6667 6.76359 10.6667 7.07422V10.4076C10.6667 10.7182 10.6667 10.8735 10.6159 10.996C10.5483 11.1594 10.4185 11.2891 10.2551 11.3568C10.1326 11.4076 9.97731 11.4076 9.66669 11.4076C9.35606 11.4076 9.20075 11.4076 9.07823 11.3568C8.91488 11.2891 8.7851 11.1594 8.71743 10.996C8.66669 10.8735 8.66669 10.7182 8.66669 10.4076V7.07422Z"
        stroke="#F6A723"
        stroke-width="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1014_22172">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0742188)"
        />
      </clipPath>
    </defs>
  </svg>
);
