import Table from "../../components/table";

type Props = {
  setNotificationDetails: React.Dispatch<React.SetStateAction<string>>;
  setIsNotificationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationTableView = ({
  setNotificationDetails,
  setIsNotificationModalOpen,
}: Props) => {
  const detail = `<div>Great news! Your "Abandoned Cart Reminder" campaign is exceeding expectations!  We've observed a significant increase in recovered sales and improved conversion rates. 
  Keep an eye on the detailed campaign analytics to understand what's driving this success.<br/>
  Here are some of the key wins we've observed:<br/>
  <ul>
  <li style="list-style:disc; margin:1rem 0 1rem 2rem;">Recovered Sales: You've recovered 15% more sales compared to the previous month. That translates to an estimated $2,500 in revenue that could have been lost!</li>
  <li style="list-style:disc; margin-left:2rem;">Improved Conversion Rates:  Customers who receive your abandoned cart reminders are converting at a 10% higher rate than those who don't. This shows the effectiveness of your email messaging and incentives in encouraging customers to complete their purchases.</li>
  </ul> 
  </div>`;
  const handleClick = () => {
    setNotificationDetails(detail);
    setIsNotificationModalOpen(true);
  };
  return (
    <div className="notification_table_container">
      <div className="table_container">
        <Table isLoading={false} headers={[]} noRecord={false}>
          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>

          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>

          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>

          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>

          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>

          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>

          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
          <tr className="table_row" onClick={handleClick}>
            <td>
              <div className="title">
                <input className="check-input" type={"checkbox"} />
                <p>You have created your first Segment</p>
              </div>
            </td>
            <td>
              <p className="content">
                Weldone! You just created your first segment. Weldone! You just
                .... <span>See More</span>
              </p>
            </td>
            <td>
              <p className="date">27th August 2024, 12:32pm</p>
            </td>
          </tr>
        </Table>
      </div>
    </div>
  );
};

export default NotificationTableView;
