export const Regenerate = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.41396C1.20542 6.39735 2.14608 4.52694 3.64251 3.1596C5.13894 1.79225 7.08639 1.02369 9.1133 1.00054C11.1402 0.977387 13.1047 1.70127 14.632 3.03408C16.1593 4.36688 17.1424 6.21532 17.3938 8.22671C17.6452 10.2381 17.1474 12.2717 15.9952 13.9394C14.843 15.6072 13.1171 16.7923 11.1468 17.2688C9.17659 17.7453 7.09988 17.4798 5.31291 16.5229C3.52595 15.5659 1 12.0806 1 12.0806M1 16.664V12.0806M1 12.0806H5.58333"
      stroke="#2563EB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
