import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  details: string;
};

const NotificationModal = ({ isOpen, close, details }: Props) => {
  return (
    <Modal className="notification_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">CAMPAIGN PERFORMANCE ALERT</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div
          className="details"
          dangerouslySetInnerHTML={{
            __html: details,
          }}
        ></div>

        <Button className="btn_secondary" text="Close" onClick={close} />
      </div>
    </Modal>
  );
};

export default NotificationModal;
