import { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import Logo from "./logo";

import FirstImg from "../assets/svg/auth-slide-1.svg";
import SecondImg from "../assets/svg/auth-slide-2.svg";
import ThirdImg from "../assets/svg/auth-slide-3.svg";

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  var tick: any = useRef();

  const slides = [
    {
      image: FirstImg,
      title: "Transform your customer data into valuable growth drivers",
      text: "Get more value out of your customer data instantly!",
    },
    {
      image: SecondImg,
      title: "Realtime customer and business insights at your fingertips",
      text: "Get more value out of your customer data instantly!",
    },
    {
      image: ThirdImg,
      title: "Develop and execute promotional campaigns in just minutes",
      text: "Get more value out of your customer data instantly!",
    },
  ];

  useEffect(() => {
    tick.current = setInterval(autoUpdateSlide, 5000);

    return () => {
      clearInterval(tick.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide]);

  const autoUpdateSlide = () => {
    const length = slides?.length - 1;
    const value = activeSlide === length ? 0 : activeSlide + 1;

    setActiveSlide(value);
  };

  return (
    <div className="carousel_container">
      <Logo lightLogo />

      <div className="section top_section">
        {slides?.map((item, index) => (
          <div
            key={index}
            className={classNames("slide", { active: activeSlide === index })}
          >
            <img src={item.image} alt="Carousel Img" />
          </div>
        ))}
      </div>

      <div className="section bottom_section">
        {slides?.map((item, index) => (
          <div
            key={index}
            className={classNames("slide", { active: activeSlide === index })}
          >
            <p className="title">{item.title}</p>
            <p className="text">{item.text}</p>
          </div>
        ))}

        <div className="controls">
          {slides?.map((item, index) => (
            <div
              key={index}
              className={classNames("item", {
                active: activeSlide === index,
              })}
              onClick={() => {
                clearInterval(tick.current);
                setActiveSlide(index);
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
