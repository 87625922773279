import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError } from "../../utils/functions";

type Props = {
  isFetchingSegments: boolean;
  segments: [];
  isFetchingSegmentData: boolean;
  segmentData: {};
  isCreatingSegment: boolean;
  isSavingSegment: boolean;
  isDeletingSegment: boolean;
};
export const initialState: Props = {
  isFetchingSegments: false,
  segments: [],
  isFetchingSegmentData: false,
  segmentData: {},
  isCreatingSegment: false,
  isSavingSegment: false,
  isDeletingSegment: false,
};

// Slice
const slice = createSlice({
  name: "segments",
  initialState,
  reducers: {
    setIsFetchingSegments: (state, { payload }) => {
      state.isFetchingSegments = payload;
    },
    getSegmentsSuccess: (state, { payload }) => {
      state.segments = payload;
    },
    setIsFetchingSegmentData: (state, { payload }) => {
      state.isFetchingSegmentData = payload;
    },
    getSegmentByIdSuccess: (state, { payload }) => {
      state.segmentData = payload;
    },
    setIsCreatingSegment: (state, { payload }) => {
      state.isCreatingSegment = payload;
    },
    setIsSavingSegment: (state, { payload }) => {
      state.isSavingSegment = payload;
    },
    setIsDeletingSegment: (state, { payload }) => {
      state.isDeletingSegment = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingSegments,
  getSegmentsSuccess,
  setIsFetchingSegmentData,
  getSegmentByIdSuccess,
  setIsCreatingSegment,
  setIsSavingSegment,
  setIsDeletingSegment,
} = slice.actions;

export const getSegmentsAction = (params?: any) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingSegments(true));

    const res = await getRequest({
      url: API_URLS?.getSegments,
      params,
    });

    dispatch(getSegmentsSuccess(res?.data?.data));
    dispatch(setIsFetchingSegments(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingSegments(false));
  }
};

export const getSegmentsByIdAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingSegmentData(true));

    const res = await getRequest({
      url: `${API_URLS?.getSegmentById}/${id}`,
      params: null,
    });

    dispatch(
      getSegmentByIdSuccess({
        ...res?.data,
        customers: JSON.parse(res?.data?.customers),
      })
    );
    dispatch(setIsFetchingSegmentData(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingSegmentData(false));
  }
};

export const generateAISegmentAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingSegment(true));
    const response = await fetch(API_URLS?.generateAISegment);
    const resp = await response.json();

    dispatch(setIsCreatingSegment(false));
    return { success: true, data: resp?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingSegment(false));
  }
};

export const generateCustomSegmentAction =
  (data: { user_query: string }) => async (dispatch: any) => {
    try {
      const response = await fetch(API_URLS?.generateCustomSegment, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const resp = await response.json();

      return { success: true, data: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postAddSegmentToDbAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsSavingSegment(true));

      const res = await postRequest({
        url: API_URLS?.addSegment,
        params: null,
        data,
        formData: null,
      });
      dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsSavingSegment(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsSavingSegment(false));
    }
  };

export const deleteSegmentsAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsDeletingSegment(true));

    const res = await getRequest({
      url: `${API_URLS?.deleteSegment}/${id}`,
      params: null,
    });

    dispatch(setIsDeletingSegment(false));
    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsDeletingSegment(false));
  }
};
