import { graph, letter } from "../../assets/img";
import Button from "../../components/button";

type Props = {
  setNotificationDetails: React.Dispatch<React.SetStateAction<string>>;
  setIsNotificationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationListView = ({
  setNotificationDetails,
  setIsNotificationModalOpen,
}: Props) => {
  const detail = `<div>Great news! Your "Abandoned Cart Reminder" campaign is exceeding expectations!  We've observed a significant increase in recovered sales and improved conversion rates. 
  Keep an eye on the detailed campaign analytics to understand what's driving this success.<br/>
  Here are some of the key wins we've observed:<br/>
  <ul>
  <li style="list-style:disc; margin:1rem 0 1rem 2rem;">Recovered Sales: You've recovered 15% more sales compared to the previous month. That translates to an estimated $2,500 in revenue that could have been lost!</li>
  <li style="list-style:disc; margin-left:2rem;">Improved Conversion Rates:  Customers who receive your abandoned cart reminders are converting at a 10% higher rate than those who don't. This shows the effectiveness of your email messaging and incentives in encouraging customers to complete their purchases.</li>
  </ul> 
  </div>`;
  const handleClick = () => {
    setNotificationDetails(detail);
    setIsNotificationModalOpen(true);
  };

  return (
    <div className="notification_list">
      <div className="inner">
        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={letter} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">
                Your black friday campaign is thriving
              </p>
              <p className="item_detail">
                This campaign has reached 30 customers so far. This campaign has
                reached 30 customers so far. This campaign <span>See more</span>
              </p>
              <p className="item_detail">27th May 2024, 12:32pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>

        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={letter} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have created your first campaign</p>
              <p className="item_detail">
                Weldone! Your "Fall Flash Sale" campaign is now live! Monitor
                performance and Monitor its performance in real-time using the
                campaign dashboard. Track key metrics like open rates,
                click-through rates, and conversions to measure its
                effectiveness and make adjustments as needed to optimize
                results.
              </p>
              <p className="item_detail">21st May 2024, 12:32pm</p>
            </div>
          </div>
        </div>

        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={graph} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have not reviewed your analytics</p>
              <p className="item_detail">
                You have some new data on your analytics page.{" "}
                <span>See more</span>
              </p>
              <p className="item_detail">20th May 2024, 10:22pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>
        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={graph} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have not reviewed your analytics</p>
              <p className="item_detail">
                You have some new data on your analytics page.{" "}
                <span>See more</span>
              </p>
              <p className="item_detail">20th May 2024, 10:22pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>
        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={graph} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have not reviewed your analytics</p>
              <p className="item_detail">
                You have some new data on your analytics page.{" "}
                <span>See more</span>
              </p>
              <p className="item_detail">20th May 2024, 10:22pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>
        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={graph} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have not reviewed your analytics</p>
              <p className="item_detail">
                You have some new data on your analytics page.{" "}
                <span>See more</span>
              </p>
              <p className="item_detail">20th May 2024, 10:22pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>
        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={graph} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have not reviewed your analytics</p>
              <p className="item_detail">
                You have some new data on your analytics page.{" "}
                <span>See more</span>
              </p>
              <p className="item_detail">20th May 2024, 10:22pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>
        <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={graph} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have not reviewed your analytics</p>
              <p className="item_detail">
                You have some new data on your analytics page.{" "}
                <span>See more</span>
              </p>
              <p className="item_detail">20th May 2024, 10:22pm</p>
            </div>
          </div>
          <div className="action">
            <Button text="See analytics" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationListView;
