import { useState } from "react";
import ReactPaginate from "react-paginate";

import FormSelect from "./form-select";

import Icon from "../assets/svg";

type Props = {
  params: {
    page: number;
    limit: number;
  };
  totalCount: number;
  changeData: ({ limit, page }: { limit: number; page: number }) => void;
};

const rowOptions = [
  { label: "50 items", value: "50" },
  { label: "100 items", value: "100" },
  { label: "150 items", value: "150" },
  { label: "200 items", value: "200" },
];

const Pagination = ({ params, totalCount, changeData }: Props) => {
  const [selected, setSelected] = useState(rowOptions[1]);
  const [pageLimit, setPageLimit] = useState(
    params?.limit ?? parseInt(rowOptions[1].value)
  );

  const handlePaginate = ({ limit, page }: { limit?: any; page?: number }) => {
    changeData({
      limit: limit !== undefined ? parseInt(limit.value) : pageLimit,
      page: page !== undefined ? page : params.page,
    });
  };

  return (
    <div className="pagination_container">
      <div className="rows">
        <p>Showing rows:</p>

        <FormSelect
          name="select"
          defaultValue={selected}
          options={rowOptions}
          onChange={(value: any) => {
            setSelected(value);
            setPageLimit(parseInt(value.value));
            handlePaginate({ limit: value });
          }}
        />
      </div>

      <ReactPaginate
        previousLabel={<Icon name="chevronDown" />}
        nextLabel={<Icon name="chevronDown" />}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={Math.round(totalCount / pageLimit)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={(value) => {
          setPageLimit(parseInt(selected?.value));
          handlePaginate({ page: value?.selected + 1 });
        }}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};

export default Pagination;
