import Button from "./button";
import FormInput from "./form-input";

type EnterPromptFormProps = {
  placeholder?: string;
  generateWithAI?: () => void;
  hideAskAI?: boolean;
  promptText: string;
  setPromptText: (value: string) => void;
  isCreating: boolean;
  generateDetails: (insightCampaignPrompt?: string) => void;
};

const EnterPromptForm = ({
  placeholder,
  generateWithAI,
  hideAskAI,
  promptText,
  setPromptText,
  isCreating,
  generateDetails,
}: EnterPromptFormProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    generateDetails();
  };

  return (
    <>
      <form className="enter_prompt_form" onSubmit={handleSubmit}>
        <FormInput
          name="prompt"
          type="text"
          value={promptText}
          placeholder={
            placeholder || "Enter a prompt to generate your campaign"
          }
          onChange={(e: any) => setPromptText(e.target.value)}
          readOnly={isCreating}
        />

        <Button
          text="Generate"
          type={"submit"}
          loading={isCreating}
          loadingText="Generating..."
          disabled={!promptText || isCreating}
        />
      </form>

      {!isCreating && !hideAskAI && (
        <p className="enter_prompt_form_ask_ai" onClick={generateWithAI}>
          &#128161; <span>Ask AI</span>
        </p>
      )}
    </>
  );
};

export default EnterPromptForm;
