import { useEffect, useState } from "react";

import Topbar from "../../components/topbar";
import Button from "../../components/button";

type Props = {
  generateWithAI?: () => void;
  generateCustom?: () => void;
  filter?: string;
  onFilter?: (value: string) => any;
};

const InsightsTopbar = ({ generateCustom, filter, onFilter }: Props) => {
  const [activeBtnFilter, setactiveBtnFilter] = useState("Active");

  const handleFilter = (selected: { label: string; value: string }) => {
    setactiveBtnFilter(selected.label);
    onFilter && onFilter(selected.value);
  };

  useEffect(() => {
    filter && setactiveBtnFilter(filter);
  }, [filter]);

  const topbarFilters = [
    {
      type: "button",
      label: "New Insights",
      value: "active",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "button",
      label: "In Progress",
      value: "in-progress",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "button",
      label: "Completed",
      value: "completed",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "button",
      label: "Rejected",
      value: "rejected",
      onClick: (value: any) => handleFilter(value),
    },
  ];

  return (
    <>
      <Topbar
        title="Insights"
        onSearch={() => {}}
        activeBtnFilter={activeBtnFilter}
        filters={topbarFilters}
        actionBtn={
          <Button
            text="Generate New Insight"
            leftIcon="plus"
            onClick={generateCustom}
          />
        }
      />
    </>
  );
};

export default InsightsTopbar;
