export const Store = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M7.2502 8.99999C7.2502 8.58578 7.58599 8.24999 8.0002 8.24999H11.0002C11.4144 8.24999 11.7502 8.58578 11.7502 8.99999C11.7502 9.4142 11.4144 9.74999 11.0002 9.74999H8.0002C7.58599 9.74999 7.2502 9.4142 7.2502 8.99999Z"
        fill="#64748B"
      />{" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28869 2.76282C1.41968 2.36986 1.84442 2.15749 2.23737 2.28848L2.54176 2.38994C3.16813 2.5987 3.69746 2.77513 4.1137 2.96876C4.55613 3.17459 4.94002 3.42968 5.23112 3.83355C5.52222 4.23743 5.64282 4.68229 5.69817 5.16711C5.70129 5.19437 5.70421 5.222 5.70696 5.24999L16.511 5.24999C17.4869 5.24995 18.3034 5.24992 18.9278 5.34236C19.5793 5.4388 20.2076 5.66048 20.6038 6.26138C21 6.86229 20.9563 7.5271 20.7883 8.16384C20.6273 8.77419 20.3057 9.52462 19.9212 10.4216L19.4544 11.5109C19.2778 11.923 19.1224 12.2855 18.961 12.5725C18.7862 12.8832 18.5728 13.1653 18.2497 13.3784C17.9266 13.5914 17.5832 13.6764 17.2288 13.7147C16.9014 13.75 16.507 13.75 16.0587 13.75H6.15378C6.22758 13.8839 6.31252 13.9943 6.40921 14.091C6.68598 14.3677 7.07455 14.5482 7.80832 14.6468C8.56367 14.7484 9.56479 14.75 11.0002 14.75H19.0002C19.4144 14.75 19.7502 15.0858 19.7502 15.5C19.7502 15.9142 19.4144 16.25 19.0002 16.25H10.9453C9.57776 16.25 8.47541 16.25 7.60845 16.1335C6.70834 16.0125 5.95047 15.7536 5.34855 15.1516C4.74664 14.5497 4.48774 13.7918 4.36673 12.8917C4.25017 12.0248 4.25018 10.9225 4.2502 9.55487L4.2502 6.88303C4.2502 6.17003 4.24907 5.69826 4.20785 5.33726C4.16883 4.99541 4.10068 4.83052 4.01426 4.71062C3.92784 4.59072 3.79296 4.47392 3.481 4.3288C3.15155 4.17554 2.70435 4.02527 2.02794 3.79981L1.76303 3.7115C1.37008 3.58052 1.15771 3.15578 1.28869 2.76282ZM5.80693 12.25H16.022C16.5179 12.25 16.8305 12.249 17.0678 12.2234C17.287 12.1997 17.3713 12.1608 17.424 12.1261C17.4766 12.0914 17.5455 12.0292 17.6537 11.8371C17.7707 11.629 17.8948 11.3421 18.0901 10.8863L18.5187 9.88631C18.9332 8.91911 19.2087 8.2713 19.3379 7.78124C19.4636 7.30501 19.3999 7.16048 19.3515 7.08712C19.3032 7.01376 19.1954 6.89831 18.7082 6.82619C18.2068 6.75196 17.5029 6.74999 16.4506 6.74999H5.7502C5.75021 6.78023 5.75021 6.81069 5.7502 6.84138L5.7502 9.49999C5.7502 10.672 5.75127 11.5544 5.80693 12.25Z"
        fill="#64748B"
      />{" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5002 21.75C6.25756 21.75 5.2502 20.7426 5.2502 19.5C5.2502 18.2573 6.25756 17.25 7.5002 17.25C8.74284 17.25 9.7502 18.2573 9.7502 19.5C9.7502 20.7426 8.74284 21.75 7.5002 21.75ZM6.7502 19.5C6.7502 19.9142 7.08599 20.25 7.5002 20.25C7.91442 20.25 8.2502 19.9142 8.2502 19.5C8.2502 19.0858 7.91442 18.75 7.5002 18.75C7.08599 18.75 6.7502 19.0858 6.7502 19.5Z"
        fill="#64748B"
      />{" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2502 19.5001C14.2502 20.7427 15.2576 21.7501 16.5002 21.7501C17.7428 21.7501 18.7502 20.7427 18.7502 19.5001C18.7502 18.2574 17.7428 17.2501 16.5002 17.2501C15.2576 17.2501 14.2502 18.2574 14.2502 19.5001ZM16.5002 20.2501C16.086 20.2501 15.7502 19.9143 15.7502 19.5001C15.7502 19.0859 16.086 18.7501 16.5002 18.7501C16.9144 18.7501 17.2502 19.0859 17.2502 19.5001C17.2502 19.9143 16.9144 20.2501 16.5002 20.2501Z"
        fill="#64748B"
      />{" "}
    </g>
  </svg>
);
