/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import PageTitle from "../../components/page-title";
import Loader from "../../components/loader";
import Carousel from "../../components/carousel";

import { useAppDispatch } from "../../redux";
import { updateUserAction } from "../../redux/auth/user-slice";
import { getAISyncToVectorDBAction } from "../../redux/dashboard/settings-slice";
import { setAlert } from "../../redux/components/components-slice";

import { didYouKnowTexts } from "../../utils/mockdata";

const ShopifyOnboardingPage = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userSlice);

  const [knowText, setKnowText] = useState(didYouKnowTexts[0]);
  const [knowTextCount, setKnowTextCount] = useState(0);
  const [aISyncCount, setAISyncCount] = useState(0);
  const [showAISyncError, setShowAISyncError] = useState(false);
  const [isSyncingStore, setIsSyncingStore] = useState(false);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    // const step = params.get('st');
    const status = params.get("status");
    const errorMsg = params.get("error_msg");

    if (status === "success" && !errorMsg) {
      handleAISyncToVectorDB();
    } else if (errorMsg) {
      dispatch(setAlert(true, "error", errorMsg));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAISyncToVectorDB = async () => {
    setIsSyncingStore(true);

    await dispatch(getAISyncToVectorDBAction()).then((resp: any) => {
      if (resp?.isCompleted === true) {
        const newUser = { ...user, onboarded: true };
        dispatch(updateUserAction({ ...newUser }));
        setTimeout(() => window.location.assign("/home"), 10000);
      } else if (resp?.isCompleted === false) {
        reRunAISyncToVectorDB();
      } else {
        setShowAISyncError(true);
        setIsSyncingStore(false);
      }
    });
  };
  const reRunAISyncToVectorDB = async () => {
    if (aISyncCount > 2) {
      setShowAISyncError(true);
    } else {
      setTimeout(() => {
        setAISyncCount(aISyncCount + 1);
        handleAISyncToVectorDB();
      }, 3000);
    }
  };

  useEffect(() => {
    const totalIndex = didYouKnowTexts?.length - 1;
    const nextIndex = knowTextCount === totalIndex ? 0 : knowTextCount + 1;

    setTimeout(() => {
      setKnowText(didYouKnowTexts[nextIndex]);
      setKnowTextCount(nextIndex);
    }, 10000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowText]);

  return (
    <React.Fragment>
      <PageTitle title="Shopify Onboarding" />

      <div className="sign_in_page">
        <div className="left_side">
          <Carousel />
        </div>

        <div className="synch_data_container">
          {isSyncingStore ? (
            <div className="generating_data">
              <p className="synching_store">
                <span>Please wait while we sync your store data...</span>
                <Loader />
                &#128161; {knowText}
              </p>
            </div>
          ) : showAISyncError ? (
            <div className="generating_data">
              <p className="synching_store">
                &#10071; Sorry, we encountered an error.
                <br />A customer success agent will contact you soon.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShopifyOnboardingPage;
