import { useState } from "react";
import { useHistory } from "react-router-dom";

import Topbar from "../../components/topbar";
import NewCustomerModal from "../../components/modal/new-customer-modal";

type Props = {
  listOrder?: any;
  setListOrder?: (value: any) => void;
  options?: any;
};

const CustomerTopbar = ({ listOrder, setListOrder, options }: Props) => {
  const history = useHistory();

  const [newCustomerModal, setNewCustomerModal] = useState(false);

  let topbarFilters: any = [];
  if (listOrder)
    topbarFilters = [
      {
        type: "select",
        value: listOrder,
        options,
        onChange: (value: any) => setListOrder && setListOrder(value),
      },
    ];

  const goToCustomersPage = () => {
    history.push("/customers");
  };

  return (
    <>
      <Topbar
        title="Customers"
        onSearch={() => {}}
        filters={topbarFilters}
        hideLayoutStyle={true}
        // actionBtn={
        //   <Button
        //     text="New Customer"
        //     leftIcon="plus"
        //     onClick={() => setNewCustomerModal(true)}
        //   />
        // }
      />

      <NewCustomerModal
        isOpen={newCustomerModal}
        close={() => setNewCustomerModal(false)}
        onSuccess={goToCustomersPage}
      />
    </>
  );
};

export default CustomerTopbar;
