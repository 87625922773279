import { useState } from "react";
import moment from "moment";

import Accordion from "../../components/accordion";
import Button from "../../components/button";
import SectionHeader from "../../components/section-header";

import Icon from "../../assets/svg";

type Props = {
  campaignData: any;
  isStartingCampaign: boolean;
  startCampaign: () => void;
  children: React.ReactNode;
};

const dateFormat = (date: Date) => {
  return `${moment(date).format("MMM DD, YYYY")}`;
};

const CampaignSchedule = ({
  campaignData,
  isStartingCampaign,
  startCampaign,
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={"campaign_details"}>
      <SectionHeader
        title={campaignData?.campaign_title}
        rightSide={
          <Button
            text="Start Campaign"
            className="start_btn"
            onClick={startCampaign}
            loading={isStartingCampaign}
          />
        }
      />
      <Accordion
        open={isOpen}
        title="Campaign Schedule"
        visibleContent={
          <div className="campaign_schedule">
            <div className="schedule_details">
              {campaignData?.segment_name ? (
                <div className="item">
                  <p className="title">SEGMENT</p>
                  <p className="segment_name">{campaignData?.segment_name}</p>
                </div>
              ) : (
                <></>
              )}

              <div className="item">
                <p className="title">START DATE</p>
                <div className="date">
                  <Icon name="calendarBlack" />
                  <p>{dateFormat(new Date(campaignData?.start_date))}</p>
                </div>
              </div>

              <div className="item">
                <p className="title">END DATE</p>
                <div className="date">
                  <Icon name="calendarBlack" />
                  <p>{dateFormat(new Date(campaignData?.end_date))}</p>
                </div>
              </div>
              <div>
                <Button
                  text={"View"}
                  rightIcon="chevronDown"
                  onClick={() => setIsOpen((prev) => !prev)}
                />
              </div>
            </div>
            <div className="schedule_details"></div>
          </div>
        }
        content={<>{children}</>}
      />
    </div>
  );
};

export default CampaignSchedule;
