import React from "react";

import Pagination from "./pagination";

type Props = {
  isLoading: boolean;
  headers: React.ReactNode[];
  noRecord: boolean;
  children: React.ReactNode;
  params?: {
    page: number;
    limit: number;
  };
  totalCount?: number;
  changeData?: ({ limit, page }: { limit: number; page: number }) => void;
};

const Table = ({
  isLoading,
  headers,
  noRecord,
  children,
  params,
  totalCount,
  changeData,
}: Props) => (
  <React.Fragment>
    <div className="table-responsive">
      <table className="table">
        {headers.length !== 0 ? (
          <thead>
            <tr>
              {headers.map((header, i) => (
                <th key={i}>{header}</th>
              ))}
            </tr>
          </thead>
        ) : (
          <></>
        )}
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={12} className="loading_text">
                Loading...
              </td>
            </tr>
          </tbody>
        ) : (
          <>
            {noRecord ? (
              <tbody>
                <tr>
                  <td colSpan={12} className="loading_text">
                    No record to show
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{children}</tbody>
            )}
          </>
        )}
      </table>
    </div>

    {totalCount && params && changeData && (
      <Pagination
        params={params}
        totalCount={totalCount}
        changeData={changeData}
      />
    )}
  </React.Fragment>
);

export default Table;
