import { useState } from "react";
import classNames from "classnames";

import Icon from "../../assets/svg";

const notificationTabData = [
  {
    name: "Segments",
    icon: "grid",
    newItems: { amount: "8 new", color: "primary" },
  },
  {
    name: "Insights",
    icon: "star",
    newItems: { amount: "8 new", color: "amber" },
  },
  {
    name: "Campaigns",
    icon: "mail",
    newItems: { amount: "8 new", color: "sky" },
  },
  {
    name: "Customers",
    icon: "user",
    newItems: { amount: "8 new", color: "pink" },
  },
  {
    name: "Analytics",
    icon: "graph",
    newItems: { amount: "8 new", color: "primary" },
  },
  // {
  //   name: "Co-pilot",
  //   icon: "exclude",
  //   newItems: { amount: "8 new", color: "amber" },
  // },
];

const NotificationTab = () => {
  const [activeTab, setActiveTab] = useState("Segments");

  const handleClick = (name: string) => {
    setActiveTab(name);
  };

  return (
    <div className="notification_tab_container">
      <div className="inner">
        {notificationTabData.map(({ name, icon, newItems }) => (
          <div
            className={classNames("tab_item", { active: name === activeTab })}
            key={name}
            onClick={() => handleClick(name)}
          >
            <Icon name={icon} />
            <p className="name">{name}</p>
            <p
              className={classNames("new", {
                primary: newItems.color === "primary",
                amber: newItems.color === "amber",
                sky: newItems.color === "sky",
                pink: newItems.color === "pink",
              })}
            >
              {newItems.amount}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationTab;
