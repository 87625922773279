import React from "react";
import classNames from "classnames";

import Icon from "../assets/svg";

type Props = {
  open?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  visibleContent?: React.ReactNode;
  content: React.ReactNode;
};

const Accordion = ({
  open,
  setIsOpen,
  title,
  visibleContent,
  content,
}: Props) => {
  return (
    <div
      className={classNames("accordion_container", {
        open,
      })}
    >
      <div className="accordion_header" onClick={() => setIsOpen?.(!open)}>
        <p className="title">{title}</p>
        <Icon name="chevronDown" />
      </div>

      <div className="visible_content">{visibleContent}</div>

      <div className="accordion_content">{content}</div>
    </div>
  );
};

export default Accordion;
