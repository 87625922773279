export const APP_NAME = "Konvas AI";

export const APP_USER = "konvas_app_user";

export const APP_DUMMY_USER = {
  id: "new_user_01",
  first_name: "John",
  last_name: "Doe",
  email: "johndoe@gmail.com",
};

export const APP_LIST_LAYOUT_LIST = "list";
export const APP_LIST_LAYOUT_GRID = "grid";

export const SHOPIFY_INSTALLATION_URL =
  "https://api.konvas.ai/ShopifyAuth/installation/skytestapp?shopify_store=";
