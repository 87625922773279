import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { useAppDispatch } from "../../../redux";

import { getBrandDataAction } from "../../../redux/dashboard/brand-slice";

import AITrainingData from "./AITrainingData";
import BrandSummary from "./BrandSummary";
import Loader from "../../../components/loader";

const tabHeader = ["AI Training Data", "Brand Summary"];

const AITraining = () => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(tabHeader[0]);
  const [isRefetchBrandData, setIsRefetchBrandData] = useState(false);
  const { brandData, isFetchingBrandData } = useSelector(
    (state: any) => state.brandSlice
  );

  useEffect(() => {
    dispatch(getBrandDataAction({}));
    if (isRefetchBrandData) setIsRefetchBrandData(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchBrandData]);

  return (
    <div className="content_container">
      <div className="ai_training">
        <div className="tab_header">
          {tabHeader.map((title: string) => (
            <div
              className={classNames("title", { active: title === activeTab })}
              key={title}
              onClick={() => setActiveTab(title)}
            >
              <p>{title}</p>
            </div>
          ))}
        </div>
        {isFetchingBrandData ? (
          <Loader />
        ) : brandData ? (
          <div>
            {activeTab === tabHeader[0] ? (
              <AITrainingData
                brandData={brandData}
                setIsRefetchBrandData={setIsRefetchBrandData}
              />
            ) : (
              <BrandSummary brandData={brandData} />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AITraining;
