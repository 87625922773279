const KlaviyoCallbackPage = () => {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const code = params.get("code");
  const state = params.get("state");
  console.log("code:", code);
  console.log("state:", state);
  return <div>Klaviyo callback</div>;
};

export default KlaviyoCallbackPage;
