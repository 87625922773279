import { Switch, Route, Redirect } from "react-router-dom";

import Navigations from "./navigations";

const UserRoutes = () => {
  let navs = Navigations || [];

  const routes = navs?.filter((x: any) => x?.to);

  const paths = routes?.map((x) => x?.to);

  const pathname = window.location.pathname;

  return (
    <Switch>
      {routes?.map((route, i) => (
        <Route
          key={i}
          exact
          path={route?.to || ""}
          component={route?.component}
        />
      ))}

      <Route
        path="*"
        render={() => (
          <Redirect to={paths.includes(pathname) ? pathname : `${paths[0]}`} />
        )}
      />
    </Switch>
  );
};

export default UserRoutes;
