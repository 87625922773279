import { funnel } from "../../assets/img";

import Button from "../../components/button";
import FormSelect from "../../components/form-select";

const NotificationDateFilter = () => {
  return (
    <div className="notification_date_filter">
      <div className="heading">
        <img src={funnel} alt="" className="filter_icon" />
        <p>Filter by</p>
      </div>
      <div className="date">
        <div>
          <p>Day</p>
          <FormSelect
            name="filterSelect"
            defaultValue={"Select One"}
            options={[{ label: "1", value: "1" }]}
            onChange={(value: any) => null}
          />
        </div>
        <div>
          <p>Month</p>
          <FormSelect
            name="filterSelect"
            defaultValue={"Select One"}
            options={[{ label: "1", value: "1" }]}
            onChange={(value: any) => null}
          />
        </div>
        <div>
          <p>Year</p>
          <FormSelect
            name="filterSelect"
            defaultValue={"Select One"}
            options={[{ label: "1", value: "1" }]}
            onChange={(value: any) => null}
          />
        </div>
      </div>
      <Button text={"Search"} />
    </div>
  );
};

export default NotificationDateFilter;
