import { useState, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { useAppDispatch } from "../../../redux";
import {
  getBrandSummaryAction,
  updateBrandFilesAction,
  updateBrandStoreDataAction,
  updateBrandSummaryAction,
  updateBrandTonesAction,
  updateBrandWritingApproachAction,
} from "../../../redux/dashboard/brand-slice";

import { brandTags, writingApproach } from "../../../utils/appdata";
import {
  getBrandToneClassNames,
  getUserDetails,
  objectToFormData,
} from "../../../utils/functions";

import { upload } from "../../../assets/img";

import Icon from "../../../assets/svg";
import pdf from "../../../assets/svg/pdf.svg";
import docx from "../../../assets/svg/docx.svg";
import csv from "../../../assets/svg/csv.svg";
import xslx from "../../../assets/svg/xslx.svg";

import Button from "../../../components/button";

type Props = {
  brandData: any;
  setIsRefetchBrandData: React.Dispatch<React.SetStateAction<boolean>>;
};

const AITrainingData = ({ brandData, setIsRefetchBrandData }: Props) => {
  const dispatch = useAppDispatch();

  const lastSelectedTags = JSON.parse(brandData.store_tones)?.map(
    (tag: string) => ({
      tag,
    })
  );

  const currentTags = brandTags.filter(
    (initialdata: { tag: string }) =>
      !lastSelectedTags.some(
        (data: { tag: string }) => initialdata.tag === data.tag
      )
  );

  const {
    isUpdatingBrandFiles,
    isUpdatingBrandTones,
    isUpdatingBrandStoreData,
    isUpdatingBrandWritingApproach,
    isUpdatingBrandSummary,
  } = useSelector((state: any) => state.brandSlice);
  const [showTagInput, setShowTagInput] = useState(false);
  const [selectedTags, setSelectedTags] = useState([...lastSelectedTags]);
  const [initialTags, setInitialTags] = useState([...currentTags]);
  const [tagToBeAdded, setTagToBeAdded] = useState("");
  const [updateType, setUpdateType] = useState("");
  const [selectedWritingApproach, setSelectedWritingApproach] = useState(
    brandData?.writing_approach
  );
  const [showWritingApproachDropDown, setShowWritingApproachDropDown] =
    useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([
    {
      name: JSON.parse(brandData?.store_docs)[0] ?? "",
      lastModified: 0,
      webkitRelativePath: "",
      size: 0,
      type: "",
      arrayBuffer: function (): Promise<ArrayBuffer> {
        throw new Error("Function not implemented.");
      },
      slice: function (
        start?: number,
        end?: number,
        contentType?: string
      ): Blob {
        throw new Error("Function not implemented.");
      },
      stream: function (): ReadableStream<Uint8Array> {
        throw new Error("Function not implemented.");
      },
      text: function (): Promise<string> {
        throw new Error("Function not implemented.");
      },
    },
  ]);
  const [storeBrandData, setStoreBrandData] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const id = brandData.id;
  const business_slug = getUserDetails()?.company_slug;

  const getFileIcon = (name: string) => {
    return name?.endsWith(".pdf")
      ? pdf
      : name?.endsWith(".csv")
      ? csv
      : name?.endsWith(".xslx") || name?.endsWith(".xls")
      ? xslx
      : docx;
  };

  // const updateBrandData = async (payload: any) => {
  //   const res = await dispatch(updateBrandDataAction(payload, false));
  //   if (res?.success === true) {
  //     // console.log("update success");
  //     const business_slug = getUserDetails()?.company_slug;
  //     const id = brandData.id;
  //     let updatedBrandData = res?.updatedBrandData;
  //     updatedBrandData.store_tones = JSON.parse(updatedBrandData.store_tones);
  //     updatedBrandData.store_docs = JSON.parse(updatedBrandData.store_docs);

  //     //get new store ai summary
  //     const result = await dispatch(getBrandSummaryAction({}));

  //     if (result?.brandSummary) {
  //       const { brandSummary } = result;
  //       const store_ai_summary = brandSummary;
  //       console.log("store_ai_summary:", store_ai_summary);

  //       await dispatch(
  //         updateBrandDataAction(
  //           {
  //             ...updatedBrandData,
  //             id,
  //             business_slug,
  //             store_ai_summary,
  //           },
  //           true
  //         )
  //       );
  //     }
  //   }
  // };
  const updateBrandData = async (payload: any, type: string) => {
    console.log("type:", type);
    const updateAction =
      type === "tonesUpdate"
        ? updateBrandTonesAction
        : type === "writingApproachUpdate"
        ? updateBrandWritingApproachAction
        : type === "filesUpdate"
        ? updateBrandFilesAction
        : updateBrandStoreDataAction;
    const res = await dispatch(updateAction(payload));
    if (res?.success === true) {
      // setIsRefetchBrandData(true);

      //get new store ai summary
      const result = await dispatch(getBrandSummaryAction({}));

      if (result?.brandSummary) {
        const updateBrandSummaryData = {
          id,
          business_slug,
          store_ai_summary: result?.brandSummary,
        };

        const summaryUpdateResponse = await dispatch(
          updateBrandSummaryAction(updateBrandSummaryData)
        );
        if (summaryUpdateResponse?.success === true) {
          setIsRefetchBrandData(true);
        }
      }
    }
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagToBeAdded(event.target.value);
  };

  const handleAddTag = () => {
    setInitialTags((prev) =>
      prev.find((data) => data.tag === tagToBeAdded)
        ? prev
        : [...prev, { tag: tagToBeAdded }]
    );
    setShowTagInput(false);
  };

  const handleSelectTag = (tagName: string) => {
    setSelectedTags((prev: any) => {
      if (prev.length > 2) return prev;
      setInitialTags((prev) => prev.filter((data) => data.tag !== tagName));
      return [...prev, { tag: tagName }];
    });
  };

  const handleRemoveSelectedTag = (tagName: string) => {
    setSelectedTags((prev) => {
      setInitialTags((prev) => [...prev, { tag: tagName }]);
      return [...prev.filter((data: { tag: string }) => data.tag !== tagName)];
    });
  };

  const handleSelectWritingApproach = (approachType: string) => {
    setSelectedWritingApproach(approachType);
    setShowWritingApproachDropDown(false);
  };

  const handleStoreBrandData = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setStoreBrandData(event.target.value);
  };

  const handleUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    console.log("files:", fileList);

    if (fileList) {
      setSelectedFiles(Array.from(fileList));
    }
  };

  // const handleSubmit = async () => {
  //   const id = brandData.id;
  //   const business_slug = getUserDetails()?.company_slug;
  //   const store_tones = selectedTags.map((data: { tag: string }) => data.tag);
  //   const store_training_data = storeBrandData;
  //   const store_docs = selectedFiles?.map((file) => file.name);
  //   const store_docs_data = "";

  //   const brandAITrainingData = {
  //     id,
  //     business_slug,
  //     store_tones,
  //     store_training_data,
  //     store_docs,
  //     store_docs_data,
  //   };

  //   updateBrandData(brandAITrainingData);
  // };

  const handleBrandToneUpdate = () => {
    const store_tones = selectedTags.map((data: { tag: string }) => data.tag);
    const { store_ai_summary } = brandData;
    const updateBrandTonesData = {
      id,
      business_slug,
      store_tones,
      store_ai_summary,
    };

    const type = "tonesUpdate";

    setUpdateType(type);

    updateBrandData(updateBrandTonesData, type);
  };

  const handleUpdateBrandStoreData = () => {
    const store_training_data = storeBrandData;
    const updateStoreData = {
      id,
      business_slug,
      store_training_data,
    };

    const type = "storeDataUpdate";

    setUpdateType(type);

    updateBrandData(updateStoreData, type);
  };

  const handleUpdateBrandWritingApproach = () => {
    const writing_approach = selectedWritingApproach;
    const updateWritingApproachData = {
      id,
      business_slug,
      writing_approach,
    };
    const type = "writingApproachUpdate";

    setUpdateType(type);

    updateBrandData(updateWritingApproachData, type);
  };

  const handleBrandFilesUpdate = () => {
    const store_docs_data = selectedFiles?.[0];
    const updateBrandFilesData = {
      id,
      business_slug,
      store_docs_data,
    };
    const type = "fileUpdate";

    setUpdateType(type);

    const formData = objectToFormData(updateBrandFilesData);
    updateBrandData(formData, type);
  };

  return (
    <Fragment>
      <div className="brand_insight_details">
        <div className="brand_section">
          <p className="number">1.</p>
          <div className="right">
            <p className="section_title">
              Pick the tag(s) that resonates best with your brand. The first tag
              you select should be your primary tone.{" "}
              <span>Select a maximum of 3</span>
            </p>
            {selectedTags.length === 0 ? (
              <></>
            ) : (
              <div className="brand_tags no-border">
                {selectedTags.map((data: { tag: string }, index: number) => (
                  <div
                    className={classNames(
                      "tag",
                      getBrandToneClassNames(index, data.tag)
                    )}
                    key={data.tag}
                  >
                    <p
                      className={classNames(
                        getBrandToneClassNames(index, data.tag)
                      )}
                    >
                      {index + 1}.
                    </p>
                    <p
                      className={classNames(
                        getBrandToneClassNames(index, data.tag)
                      )}
                    >
                      {data.tag}
                    </p>
                    <div
                      className="remove_tag"
                      onClick={() => handleRemoveSelectedTag(data.tag)}
                    >
                      <Icon name="close" />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="brand_tags">
              {initialTags.map((data, index) => (
                <div
                  className={classNames(
                    "tag",
                    getBrandToneClassNames(index, data.tag)
                  )}
                  key={data.tag}
                  onClick={() => handleSelectTag(data.tag)}
                >
                  <div
                    className={classNames(
                      "dot",
                      getBrandToneClassNames(index, data.tag)
                    )}
                  ></div>
                  <p
                    className={classNames(
                      getBrandToneClassNames(index, data.tag)
                    )}
                  >
                    {data.tag}
                  </p>
                </div>
              ))}
              <div className="add_tag" onClick={() => setShowTagInput(true)}>
                <p>+</p>
                <p>Add</p>
              </div>
            </div>
            {showTagInput ? (
              <div className="tag_input">
                <p>Add tag</p>
                <div className="input_container" onChange={handleTagChange}>
                  <input />
                  <div className="add_btn" onClick={handleAddTag}>
                    <p>+</p>
                    <p>Add</p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="update_btn">
              <Button
                text="update"
                loading={
                  (isUpdatingBrandTones || isUpdatingBrandSummary) &&
                  updateType === "tonesUpdate"
                }
                disabled={
                  (isUpdatingBrandTones || isUpdatingBrandSummary) &&
                  updateType === "tonesUpdate"
                }
                onClick={handleBrandToneUpdate}
              />
            </div>
          </div>
        </div>

        <div className="brand_section">
          <p className="number">2.</p>
          <div className="right">
            <p className="section_title">
              Use the box to provide me with more data to understand your brand
              better.
            </p>
            <div className="text_area">
              <textarea
                rows={7}
                placeholder="Enter a description..."
                onChange={handleStoreBrandData}
              />
            </div>
            <div className="update_btn">
              <Button
                text="update"
                loading={
                  (isUpdatingBrandStoreData || isUpdatingBrandSummary) &&
                  updateType === "storeDataUpdate"
                }
                disabled={
                  (isUpdatingBrandStoreData || isUpdatingBrandSummary) &&
                  updateType === "storeDataUpdate"
                }
                onClick={handleUpdateBrandStoreData}
              />
            </div>
          </div>
        </div>

        <div className="brand_section">
          <p className="number">3.</p>
          <div className="right">
            <p className="section_title">Select your writing approach</p>
            <div className="writing_approach">
              <div
                className="writing_approach_select"
                onClick={() => setShowWritingApproachDropDown((prev) => !prev)}
              >
                {selectedWritingApproach ? (
                  <div className="approach_tag">
                    <p>{selectedWritingApproach}</p>
                  </div>
                ) : (
                  <p>Pick approach</p>
                )}
                <Icon name="chevronDown" />
              </div>
              {showWritingApproachDropDown && (
                <div className="approach_select_dropdown">
                  {writingApproach.map((data) => (
                    <div
                      className="approach_tag"
                      key={data.type}
                      onClick={() => handleSelectWritingApproach(data.type)}
                    >
                      <p>{data.type}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="update_btn">
              <Button
                text="update"
                loading={
                  (isUpdatingBrandWritingApproach || isUpdatingBrandSummary) &&
                  updateType === "writingApproachUpdate"
                }
                disabled={
                  (isUpdatingBrandWritingApproach || isUpdatingBrandSummary) &&
                  updateType === "writingApproachUpdate"
                }
                onClick={handleUpdateBrandWritingApproach}
              />
            </div>
          </div>
        </div>

        <div className="brand_section">
          <p className="number">4.</p>
          <div className="right">
            <p className="section_title">
              Upload any document or store data that will help the AI understand
              your brand better.{" "}
              <span>
                E.g Brand tone document, brand assets, store context, products
                data e.t.c
              </span>
            </p>
            <div className="file_upload">
              <div className="upload" onClick={handleUpload}>
                <input
                  type="file"
                  ref={fileInputRef}
                  // multiple
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.csv"
                />
                <div className="upload_icon">
                  <img src={upload} alt="upload-icon" />
                </div>
                <p>
                  <span>Click to upload</span> or drag and drop
                </p>
                <p className="file_format">PDF, DOC, EXCEL or CSV (max 20mb)</p>
              </div>
              {!selectedFiles[0]?.name ? (
                <></>
              ) : (
                selectedFiles.map((file: File) => (
                  <div className="uploaded_files" key={file.name}>
                    <img src={getFileIcon(file.name)} alt="" />
                    <div className="file">
                      <p>{file.name}</p>
                      {/* <p>size: {file.size}</p> */}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="update_btn">
              <Button
                text="update"
                loading={
                  (isUpdatingBrandFiles || isUpdatingBrandSummary) &&
                  updateType === "fileUpdate"
                }
                disabled={
                  (isUpdatingBrandFiles || isUpdatingBrandSummary) &&
                  updateType === "fileUpdate"
                }
                onClick={handleBrandFilesUpdate}
              />
            </div>
          </div>
        </div>
        {/* <div className="btn">
          <Button
            text={"Save"}
            disabled={isUpdateBtnDisabled}
            loading={isUpdatingBrandData}
            onClick={handleSubmit}
          />
        </div> */}
      </div>
    </Fragment>
  );
};

export default AITrainingData;
