import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  aiDetails: string;
};

const CampaignAIAgentModal = ({ isOpen, close, aiDetails }: Props) => {
  return (
    <Modal className="campaign_ai_agent_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <div className="title_box">Campaign {">"} AI Agent Information</div>
          }
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div
          className="ai_details"
          dangerouslySetInnerHTML={{
            __html: aiDetails,
          }}
        ></div>

        <Button className="btn_secondary" text="Close" onClick={close} />
      </div>
    </Modal>
  );
};

export default CampaignAIAgentModal;
