import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { useAppDispatch } from "../../redux";

import { brandTags, writingApproach } from "../../utils/appdata";
import {
  getUserDetails,
  objectToFormData,
  getBrandToneClassNames,
} from "../../utils/functions";

import { upload } from "../../assets/img";

import Icon from "../../assets/svg";
import pdf from "../../assets/svg/pdf.svg";
import docx from "../../assets/svg/docx.svg";
import csv from "../../assets/svg/csv.svg";
import xslx from "../../assets/svg/xslx.svg";

import Button from "../../components/button";
import { submitBrandDataAction } from "../../redux/dashboard/brand-slice";

const BrandInsightDetails = () => {
  const dispatch = useAppDispatch();

  const { isSubmittingBrandData } = useSelector(
    (state: any) => state.brandSlice
  );

  const [showTagInput, setShowTagInput] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [initialTags, setInitialTags] = useState([...brandTags]);
  const [tagToBeAdded, setTagToBeAdded] = useState("");
  const [selectedWritingApproach, setSelectedWritingApproach] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>([]);
  const [storeBrandData, setStoreBrandData] = useState("");
  const [showWritingApproachDropDown, setShowWritingApproachDropDown] =
    useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const isSubmitBtnDisabled =
    selectedTags.length === 0 && selectedFiles?.length === 0 && !storeBrandData;

  const getFileIcon = (type: string) => {
    switch (type) {
      case "application/pdf":
        return pdf;
      case "text/csv":
        return csv;
      case "application/msword":
        return docx;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return docx;
      case "application/vnd.ms-excel":
        return xslx;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return xslx;
      default:
        return pdf;
    }
  };

  const sendBrandDataToDB = (payload: any) => {
    dispatch(submitBrandDataAction(payload)).then((res: any) => {
      if (res?.success === true) {
        window.location.assign("/home");
      }
    });
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagToBeAdded(event.target.value);
  };

  const handleAddTag = () => {
    setInitialTags((prev) =>
      prev.find((data) => data.tag === tagToBeAdded)
        ? prev
        : [...prev, { tag: tagToBeAdded }]
    );
    setShowTagInput(false);
  };

  const handleSelectTag = (tagName: string) => {
    setSelectedTags((prev: any) => {
      if (prev.length > 2) return prev;
      setInitialTags((prev) => prev.filter((data) => data.tag !== tagName));
      return [...prev, { tag: tagName }];
    });
  };

  const handleRemoveSelectedTag = (tagName: string) => {
    setSelectedTags((prev) => {
      setInitialTags((prev) => [...prev, { tag: tagName }]);
      return [...prev.filter((data: { tag: string }) => data.tag !== tagName)];
    });
  };

  const handleSelectWritingApproach = (approachType: string) => {
    setSelectedWritingApproach(approachType);
    setShowWritingApproachDropDown(false);
  };

  const handleStoreBrandData = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setStoreBrandData(event.target.value);
  };

  const handleUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    console.log("files:", fileList);

    if (fileList) {
      setSelectedFiles(Array.from(fileList));
    }
  };

  const handleSubmit = async () => {
    const business_slug = getUserDetails()?.company_slug;
    const store_tones = selectedTags.map((data: { tag: string }) => data.tag);
    const store_training_data = storeBrandData;
    const store_docs = selectedFiles?.map((file) => file.name);
    const store_docs_data = selectedFiles?.[0];
    const writing_approach = selectedWritingApproach;

    console.log("store_docs_data:", store_docs_data);

    const brandAITrainingData = {
      business_slug,
      store_tones,
      store_training_data,
      store_docs,
      store_docs_data,
      writing_approach,
    };

    console.log("aiTrainingData:", brandAITrainingData);

    const formData = objectToFormData(brandAITrainingData);

    sendBrandDataToDB(formData);
  };

  return (
    <div className="brand_insight_details">
      <div className="brand_section">
        <p className="number">1.</p>
        <div className="right">
          <p className="section_title">
            Pick the tag(s) that resonates best with your brand. The first tag
            you select should be your primary tone.{" "}
            <span>Select a maximum of 3</span>
          </p>
          {selectedTags.length === 0 ? (
            <></>
          ) : (
            <div className="brand_tags no-border">
              {selectedTags.map((data: { tag: string }, index: number) => (
                <div
                  className={classNames(
                    "tag",
                    getBrandToneClassNames(index, data.tag)
                  )}
                  key={data.tag}
                >
                  <p
                    className={classNames(
                      getBrandToneClassNames(index, data.tag)
                    )}
                  >
                    {index + 1}.
                  </p>
                  <p
                    className={classNames(
                      getBrandToneClassNames(index, data.tag)
                    )}
                  >
                    {data.tag}
                  </p>
                  <div
                    className="remove_tag"
                    onClick={() => handleRemoveSelectedTag(data.tag)}
                  >
                    <Icon name="close" />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="brand_tags">
            {initialTags.map((data, index) => (
              <div
                className={classNames(
                  "tag",
                  getBrandToneClassNames(index, data.tag)
                )}
                key={data.tag}
                onClick={() => handleSelectTag(data.tag)}
              >
                <div
                  className={classNames(
                    "dot",
                    getBrandToneClassNames(index, data.tag)
                  )}
                ></div>
                <p
                  className={classNames(
                    getBrandToneClassNames(index, data.tag)
                  )}
                >
                  {data.tag}
                </p>
              </div>
            ))}
            <div className="add_tag" onClick={() => setShowTagInput(true)}>
              <p>+</p>
              <p>Add</p>
            </div>
          </div>
          {showTagInput ? (
            <div className="tag_input">
              <p>Add tag</p>
              <div className="input_container" onChange={handleTagChange}>
                <input />
                <div className="add_btn" onClick={handleAddTag}>
                  <p>+</p>
                  <p>Add</p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="brand_section">
        <p className="number">2.</p>
        <div className="right">
          <p className="section_title">
            Use the box to provide more context to understand your brand better.
          </p>
          <div className="text_area">
            <textarea
              rows={7}
              placeholder="Enter a description..."
              onChange={handleStoreBrandData}
            />
          </div>
        </div>
      </div>

      <div className="brand_section">
        <p className="number">3.</p>
        <div className="right">
          <p className="section_title">Select your writing approach</p>
          <div className="writing_approach">
            <div
              className="writing_approach_select"
              onClick={() => setShowWritingApproachDropDown((prev) => !prev)}
            >
              {selectedWritingApproach ? (
                <div className="approach_tag">
                  <p>{selectedWritingApproach}</p>
                </div>
              ) : (
                <p>Pick approach</p>
              )}
              <Icon name="chevronDown" />
            </div>
            {showWritingApproachDropDown && (
              <div className="approach_select_dropdown">
                {writingApproach.map((data) => (
                  <div
                    className="approach_tag"
                    key={data.type}
                    onClick={() => handleSelectWritingApproach(data.type)}
                  >
                    <p>{data.type}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="brand_section">
        <p className="number">4.</p>
        <div className="right">
          <p className="section_title">
            Upload any document or store data that will help the AI understand
            your brand better.{" "}
            <span>
              E.g Brand tone document, brand assets, store context, products
              data e.t.c
            </span>
          </p>
          <div className="file_upload">
            <div className="upload" onClick={handleUpload}>
              <input
                type="file"
                ref={fileInputRef}
                // multiple
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.xls,.xlsx,.csv"
              />
              <div className="upload_icon">
                <img src={upload} alt="upload-icon" />
              </div>
              <p>
                <span>Click to upload</span> or drag and drop
              </p>
              <p className="file_format">PDF, DOC, EXCEL or CSV (max 20mb)</p>
            </div>
            {selectedFiles?.map((file: File) => (
              <div className="uploaded_files">
                <img src={getFileIcon(file.type)} alt="" />
                <div className="file">
                  <p>{file.name}</p>
                  <p>size: {file.size}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="btn">
        <Button
          text={"Submit"}
          disabled={isSubmitBtnDisabled}
          loading={isSubmittingBrandData}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default BrandInsightDetails;
