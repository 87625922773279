import { useState } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";

import { updatePasswordAction } from "../../redux/auth/user-slice";

import { passwordFormValidation } from "../../utils/functions";

type FormInputs = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(updatePasswordAction(data)).then((res) => {
      if (res?.success === true) {
        reset();
      }
    });
    setIsSubmitting(false);
  };

  return (
    <div className="content_container">
      <form
        className="form password_form"
        onSubmit={handleSubmit(submitFormOnSubmit)}
      >
        <p className="title">Change your password to a new one</p>

        <FormInput
          type={showPassword ? "text" : "password"}
          placeholder="Old password"
          readOnly={isSubmitting}
          errorMessage={errors?.oldPassword?.message}
          inputRef={{
            ...register("oldPassword", passwordFormValidation(true)),
          }}
          rightIcon={showPassword ? "eyeOff" : "eye"}
          iconClick={() => setShowPassword(!showPassword)}
        />
        <FormInput
          type={showPassword ? "text" : "password"}
          placeholder="New password"
          readOnly={isSubmitting}
          errorMessage={errors?.newPassword?.message}
          inputRef={{
            ...register("newPassword", passwordFormValidation(true)),
          }}
          rightIcon={showPassword ? "eyeOff" : "eye"}
          iconClick={() => setShowPassword(!showPassword)}
        />
        <FormInput
          type={showPassword ? "text" : "password"}
          placeholder="Confirm new password"
          readOnly={isSubmitting}
          errorMessage={errors?.confirmPassword?.message}
          inputRef={{
            ...register("confirmPassword", passwordFormValidation(true)),
          }}
          rightIcon={showPassword ? "eyeOff" : "eye"}
          iconClick={() => setShowPassword(!showPassword)}
        />

        <div className="action">
          <Button
            text="Save Changes"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
