import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";

import { updateProfileAction } from "../../redux/auth/user-slice";

import {
  textFormValidation,
  emailFormValidation,
  numberFormValidation,
} from "../../utils/functions";

type FormInputs = {
  name: string;
  email: string;
  phone: string;
};

const Profile = () => {
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      name: user?.user_name,
      email: user?.email,
      phone: user?.phone,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(updateProfileAction(data)).then((res) => {
      if (res?.success === true) {
        window.location.reload();
      }
    });
    setIsSubmitting(false);
  };

  return (
    <div className="content_container">
      <form
        className="form profile_form"
        onSubmit={handleSubmit(submitFormOnSubmit)}
      >
        <p className="title">Update your profile information</p>

        <FormInput
          type="text"
          placeholder="Name"
          readOnly={isSubmitting}
          errorMessage={errors?.name?.message}
          inputRef={{
            ...register("name", textFormValidation(true)),
          }}
        />
        <FormInput
          type="text"
          placeholder="Email address"
          readOnly={isSubmitting}
          errorMessage={errors?.email?.message}
          inputRef={{
            ...register("email", emailFormValidation(true)),
          }}
        />
        <FormInput
          type="text"
          placeholder="Phone number"
          readOnly={isSubmitting}
          errorMessage={errors?.phone?.message}
          inputRef={{
            ...register("phone", numberFormValidation(true, 5, 20)),
          }}
        />

        <div className="action">
          <Button
            text="Save Changes"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
          />
        </div>
      </form>
    </div>
  );
};

export default Profile;
