export const StarFilled = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0938 17.75L5.92183 20.995L7.10083 14.122L2.10083 9.25495L9.00083 8.25495L12.0868 2.00195L15.1728 8.25495L22.0728 9.25495L17.0728 14.122L18.2518 20.995L12.0938 17.75Z"
      fill="#F6A723"
      stroke="#F6A723"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
