import { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import Button from "./button";

import google from "../assets/svg/google.svg";

const GoogleAuth = ({
  onSubmit,
  isSubmitting,
  setIsSubmitting,
}: {
  onSubmit: (profile: any) => void;
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
}) => {
  const [user, setUser] = useState<any>(null);

  const loginUser = useGoogleLogin({
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      setIsSubmitting(true);

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          onSubmit(res.data);
        })
        .catch((err) => {
          setIsSubmitting(false);
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="google_auth">
      <div className="rule_text">
        <div className="rule"></div>
        <p>Or sign in with</p>
        <div className="rule"></div>
      </div>

      <Button
        className="btn_tertiary"
        type="button"
        text={
          <>
            <img src={google} alt="Google Img" className="icon" />
            <p>Google</p>
          </>
        }
        onClick={loginUser}
        loading={isSubmitting}
        disabled={isSubmitting}
      />
    </div>
  );
};

export default GoogleAuth;
