type Props = {
  name: string;
  value: string;
  color?: string;
};

const Tag = ({ name, value }: Props) => {
  return (
    <div className="tag_container">
      <p className="name">{name}</p>
      <p className="value">{value}</p>
    </div>
  );
};

export default Tag;
