export const InfoCircle = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.07959 14.0764C11.3933 14.0764 14.0796 11.3901 14.0796 8.07642C14.0796 4.76271 11.3933 2.07642 8.07959 2.07642C4.76588 2.07642 2.07959 4.76271 2.07959 8.07642C2.07959 11.3901 4.76588 14.0764 8.07959 14.0764Z"
      stroke="#94A3B8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.07959 5.40967V8.07633"
      stroke="#94A3B8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.07959 10.7432H8.08626"
      stroke="#94A3B8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
