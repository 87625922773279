import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import FormInput from "../form-input";
import Button from "../button";
import EnterPromptForm from "../enter-prompt";

import Icon from "../../assets/svg";

import {
  generateAIInsightAction,
  generateCustomInsightAction,
} from "../../redux/dashboard/insights-slice";

type Props = {
  isOpen: boolean;
  close: any;
  onSubmit: (payload: any, state?: any) => void;
};

const NewCustomInsightModal = ({ isOpen, close, onSubmit }: Props) => {
  // const history = useHistory();
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { isSavingInsight } = useSelector((state: any) => state.insightsSlice);

  const [step, setStep] = useState<Number>(1);
  const [type, setType] = useState("");
  const [promptText, setPromptText] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [generatedData, setGeneratedData] = useState<any>({});
  const [title, setTitle] = useState("");

  useEffect(() => {
    setStep(1);
    setTitle("");
  }, [isOpen]);

  const generateWithAI = () => {
    setIsGenerating(true);
    setType("ai");

    dispatch(generateAIInsightAction()).then((res) => {
      if (res?.success) {
        setGeneratedData(Array.isArray(res?.data) ? res?.data[0] : res?.data);
        setStep(2);
      }

      setIsGenerating(false);
    });
  };

  const handleGenerateCustom = async () => {
    setIsGenerating(true);
    setType("custom");

    await dispatch(
      generateCustomInsightAction({
        user_query: promptText,
      })
    ).then((res: any) => {
      if (res?.success) {
        setGeneratedData(Array.isArray(res?.data) ? res?.data[0] : res?.data);
        setPromptText("");
        setStep(2);
      }
    });

    setIsGenerating(false);
  };

  useEffect(() => {
    setTitle(generatedData?.insight_title);
  }, [generatedData]);

  const handleSubmit = () => {
    const payload = {
      business_slug: user?.company_slug,
      type,
      insight_title: title,
      insight_details: generatedData?.insight_details,
    };

    onSubmit(payload);
  };

  const handleSaveInsightGenerateCampaigns = () => {
    const payload = {
      business_slug: user?.company_slug,
      type,
      insight_title: title,
      insight_details: generatedData?.insight_details,
    };
    // handleSubmit();
    const state = generatedData?.insight_details;
    // history.push("/campaigns", state);
    onSubmit(payload, state);
  };

  const isDisabled = () => {
    var value = false;

    if (!title) value = true;
    if (!generatedData?.insight_details) value = true;

    return value;
  };

  return (
    <Modal className="new_ai_segment_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <div className="title_box">Insight {">"} New Generated Insight</div>
          }
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      {step === 1 && (
        <div className="modal_body">
          <EnterPromptForm
            generateWithAI={generateWithAI}
            placeholder="Enter a prompt to generate your insight"
            promptText={promptText}
            setPromptText={setPromptText}
            isCreating={isGenerating}
            generateDetails={handleGenerateCustom}
          />
          <br />
          <br />
        </div>
      )}

      {step === 2 && (
        <div className="modal_body">
          <div className="info">
            <label>Insight title:</label>
            <FormInput
              type="text"
              placeholder="Insight title"
              value={title}
              readOnly={isSavingInsight}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="info">
            <label>Insight details:</label>
            <p>{generatedData?.insight_details}</p>
          </div>

          <div className="create_custom_insight_actions">
            <Button
              text="Save Insight and Generate Campaign"
              className="generate_insight_campaign"
              disabled={isDisabled()}
              onClick={handleSaveInsightGenerateCampaigns}
              loading={isSavingInsight}
              loadingText="saving..."
            />
            <Button
              text="Save New Insight"
              disabled={isDisabled()}
              onClick={handleSubmit}
              loading={isSavingInsight}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default NewCustomInsightModal;
