import { Modal } from "reactstrap";

import Button from "../button";
import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
};

const CampaignPersonalizedModal = ({ isOpen, close }: Props) => {
  return (
    <Modal className="campaign_personalization_modal" isOpen={isOpen}>
      <div className="modal_body">
        <div className="icon" onClick={close}>
          <Icon name="close" />
        </div>
        <div className="personalization">
          <p className="title">
            How we personalize your
            <br /> Campaigns{" "}
          </p>
          <p className="text">
            AI generates personalized campaigns by analyzing vast amounts of
            customer, product, and market data to tailor content and offers to
            individual preferences. Machine learning algorithms identify
            patterns and predict future behavior, enabling AI to create
            personalized product recommendations, targeted email subject lines,
            and dynamic website content.
            <br />
            <br />
            AI also optimizes offers, such as discounts and loyalty rewards, and
            selects the most effective channels to reach each customer, like
            email or social media. By continuously testing and adjusting
            campaigns in real-time, AI ensures maximum engagement and conversion
            rates, leading to a more relevant and enjoyable customer experience.
          </p>
          <div className="btn_div">
            <Button text="Go back" onClick={close} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CampaignPersonalizedModal;
