export const Star = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0001 16.2709L5.34243 19.2455L6.42318 12.9452L1.83984 8.48379L8.16484 7.56712L10.9937 1.83521L13.8225 7.56712L20.1475 8.48379L15.5642 12.9452L16.6449 19.2455L11.0001 16.2709Z"
      stroke="#64748B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
