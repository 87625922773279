import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  ArcElement,
  Legend,
} from "chart.js";

type Props = {
  reportData: any;
};

const CampaignReportChart = ({ reportData }: Props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    ArcElement,
    Legend
  );

  const { reached, opened, unsubscribed, clicked } = reportData;

  const labels = ["Reach", "No. of Opens", "Clicks", "Unsubscribed"];
  const data = {
    labels: labels,
    // xAxisID: "Campaign Performance",
    datasets: [
      {
        label: "campaign progress chart",
        data: [reached, opened, clicked, unsubscribed],
        backgroundColor: [
          "rgba(255, 228, 183, 0.4)",
          "rgba(166, 242, 197, 0.4)",
          "rgba(250, 177, 213, 0.4)",
          "rgba(56, 189, 248, 0.4)",
        ],
        borderColor: [
          "rgb(255, 228, 183)",
          "rgb(166, 242, 197)",
          "rgb(250, 177, 213)",
          "rgb(56, 189, 248)",
        ],
        borderWidth: 1.5,
        borderRadius: 36,
        minBarLength: 10,
      },
    ],
  };

  const options = {
    type: "bar",
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart">
      <p className="title">CHART REPORT</p>
      <Bar data={data} options={options} />
    </div>
  );
};

export default CampaignReportChart;
