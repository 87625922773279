export const CalendarBlack = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1112 4.16602H5.11125C4.19077 4.16602 3.44458 4.91221 3.44458 5.83268V15.8327C3.44458 16.7532 4.19077 17.4993 5.11125 17.4993H15.1112C16.0317 17.4993 16.7779 16.7532 16.7779 15.8327V5.83268C16.7779 4.91221 16.0317 4.16602 15.1112 4.16602Z"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.4446 2.5V5.83333"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.77795 2.5V5.83333"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.44458 9.16602H16.7779"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.27795 12.5H10.1113"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.1112 12.5V15"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
