import { useState } from "react";

import Topbar from "../../components/topbar";
import Button from "../../components/button";

type Props = {
  generateWithAI?: () => void;
  generateCustom?: () => void;
  onFilter?: (value: string) => any;
};

const SegmentsTopbar = ({ generateCustom, onFilter }: Props) => {
  const [activeBtnFilter, setactiveBtnFilter] = useState("AI Generated");

  const handleFilter = (selected: { label: string; value: string }) => {
    setactiveBtnFilter(selected.label);
    onFilter && onFilter(selected.value);
  };

  const topbarFilters = [
    {
      type: "button",
      label: "AI Generated",
      value: "ai",
      onClick: (value: any) => handleFilter(value),
    },
    {
      type: "button",
      label: "Custom",
      value: "custom",
      onClick: (value: any) => handleFilter(value),
    },
  ];

  return (
    <Topbar
      title="Segments"
      onSearch={() => {}}
      activeBtnFilter={activeBtnFilter}
      filters={topbarFilters}
      actionBtn={
        <Button text="New Segment" leftIcon="plus" onClick={generateCustom} />
      }
    />
  );
};

export default SegmentsTopbar;
