import Icon from "../../assets/svg";

// import Button from "../../components/button";
import Logo from "../../components/logo";
import BrandInsightDetails from "./BrandInsightDetails";

const BrandInsightsPage = () => {
  return (
    <div className="brand_insights_page_container ">
      <Logo />
      <div className="header">
        <div className="bread_crumbs">
          <p>Sign Up</p>
          <Icon name="chevronRight" />
          <p>Onboarding Questions</p>
          <Icon name="chevronRight" />
          <p className="store_data">AI Training</p>
        </div>
        {/* <Button text={"Submit"}  /> */}
      </div>
      <div className="body_content">
        <p className="title">AI Training</p>
        <p>
          Provide the following information about your store. This data will
          help us optimize your experience, tailor recommendations, or process
          your requests.{" "}
        </p>
        <BrandInsightDetails />
      </div>
    </div>
  );
};

export default BrandInsightsPage;
